import { Button, ButtonProps, styled } from '@mui/material';

const PublicButton = styled(Button)<ButtonProps>(() => ({
    color: '#F38F2F',
    // backgroundColor: purple[500],
    // backgroundColor: '#F38F2F',
    border: '1px solid #F38F2F',
    '&:hover': {
        backgroundColor: '#F38F2F',
        color: 'white',
    },
}));

export default PublicButton;
