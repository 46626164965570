import React, { ReactElement, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { setup, setupWithToken } from '../../services/fetch';
import { getTokenFromCode } from '../../services/auth';
import { clearVerifier } from '../../utils/pkce';
import { AuthContext, AuthStatus } from '../../auth/AuthProvider';
import { useConfig } from '../../config/ConfigProvider';
import { getUserInfo } from '../../redux/actions/userAction';

const Root = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    flex-direction: column;
    font-size: 30px;

    @keyframes flickerAnimation {
        /* flame pulses */
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.1;
        }
        100% {
            opacity: 1;
        }
    }
`;

const StyledLogo = styled.div`
    margin-bottom: 40px;
    animation: flickerAnimation 2.5s infinite;
`;

const AuthCallback = (): ReactElement => {
    const location = useLocation();
    const history = useNavigate();
    const { config } = useConfig();
    const dispatch = useDispatch();
    const authContext = useContext(AuthContext);

    useEffect(() => {
        if (location?.search && authContext.authStatus !== AuthStatus.SignedIn) {
            if (config?.apiUrl) {
                const _getTokenFromCode = async () => {
                    setup(config.apiUrl);
                    const urlSearchParams = new URLSearchParams(location.search);
                    const params = Object.fromEntries(urlSearchParams.entries());
                    const { data, error: resError } = await getTokenFromCode(params.code, params.state, `${config.serverUri}/authCallback`);

                    clearVerifier();
                    if (!resError) {
                        // prevent check to try login again
                        history({
                            pathname: location.pathname,
                            search: '',
                        });
                        setupWithToken(config.apiUrl, data.token);
                        // set auth context so now we are logged in
                        authContext.setToken(data.token);
                        dispatch(getUserInfo());
                    } else {
                        history({
                            pathname: '/',
                            search: `?error=unavailable_retry`,
                        });
                    }
                };
                _getTokenFromCode();
            }
        } else {
            history({
                pathname: '/',
            });
        }
    }, [config, location, authContext, history, dispatch]);

    return (
        <Root>
            <StyledLogo />
            <p style={{ color: '#000000' }}>Loading...</p>
        </Root>
    );
};

export default AuthCallback;
