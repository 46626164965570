import { getVerifier } from '../utils/pkce';
import { getInstance } from './fetch';

export const getTokenFromCode = async (code: string, state: string, requestedUri: string): Promise<any> => {
    const verifier = await getVerifier();
    const { data, error } = await getInstance().post<
        { data: Record<string, string>; error: string },
        {
            code: string;
            code_verifier: string;
            codeVerifier: string;
            state: string;
            requestedUri: string;
        }
    >('/auth/login', {
        code,
        code_verifier: verifier,
        codeVerifier: verifier,
        state,
        requestedUri,
    });
    return { data, error };
};

export const initiateAuth = async (serverUri: string, challenge: string): Promise<any> => {
    return getInstance().get<any>(`/auth/redirect?requestedUri=${serverUri}/authCallback&hash=${challenge}`);
};

export const logout = async (serverUri: string): Promise<any> => {
    return getInstance().get<any>(`/auth/logout?requestedUri=${serverUri}/`);
};

export const changePassword = async (serverUri: string): Promise<any> => {
    return getInstance().get<any>(`/auth/priv/forgotPassword?requestedUri=${serverUri}/logout`);
};
