import { Button, Chip, Divider, Grid, IconButton, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getInstance } from '../../../../services/fetch';
import { useNavigate, useParams } from 'react-router-dom';
import { Job } from '../../../../types/job';
import { Organisation } from '../../../../types/organisation';
import { validateEditorState } from '../../../../components/RichTextEditor/utils/validator';
import Loader from '../../../../components/loader';
import styled from 'styled-components';
import { GenericModal } from '../../../../components/modal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const JobDetails: React.FC = () => {
    const { jobId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState<boolean>(false);
    // const profile = useSelector(selectProfileData);

    const GrayedButton = styled(Button)`
        background-color: #f5f5f5;
        color: #000;
        &:hover {
            background-color: #f5f5f5;
        }
    `;

    const JobQuery = useQuery(
        ['JobDetails', jobId],
        async () => {
            const jobResponse = await getInstance().get<{ data: Job & { hasApplied: boolean; skillInformation: any }; error: string }>(
                `/public/job/view/${jobId}`
            );
            if (jobResponse.error) {
                throw new Error(jobResponse.error);
            }
            if (!jobResponse.data.candidate) {
                jobResponse.data.candidate = [];
            }
            const payload = jobResponse.data;
            dispatch({
                type: 'SET_SELECTED_JOB',
                payload,
            });
            const organizationResponse = await getInstance().get<{ data: Organisation; error: string }>(
                `/public/organization/${jobResponse.data.organization}`
            );
            if (organizationResponse.error) {
                throw new Error(organizationResponse.error);
            }
            return { jobDetail: jobResponse.data, organization: organizationResponse.data };
        },
        {
            cacheTime: 1000,
            staleTime: 1000,
        }
    );

    function handleApply() {
        navigate('./profile');
    }

    function handleAlreadyApplied() {
        setOpen(true);
    }

    if (JobQuery.isLoading) return <Loader />;

    return (
        <>
            <Grid container mt={'2rem'}>
                <Grid item xs={10} display={'flex'}>
                    <Typography variant="h5" mr={'1rem'}>
                        <IconButton
                            color="primary"
                            aria-label="back"
                            onClick={() => {
                                navigate('../jobs');
                            }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        {JobQuery.data?.jobDetail.id}
                    </Typography>
                    <Typography variant="h5" color={'primary'}>
                        {JobQuery.data?.jobDetail.title}, {JobQuery.data?.jobDetail.city}, {JobQuery.data?.jobDetail.state},{' '}
                        {JobQuery.data?.jobDetail.securityClearance}
                    </Typography>
                </Grid>
                <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
                    {!JobQuery.data?.jobDetail.hasApplied && (
                        <Button onClick={handleApply} variant="contained" sx={{ marginRight: '1rem' }}>
                            Apply Now
                        </Button>
                    )}
                    {JobQuery.data?.jobDetail.hasApplied && (
                        <GrayedButton onClick={handleAlreadyApplied} variant="contained" sx={{ marginRight: '1rem' }}>
                            You have applied
                        </GrayedButton>
                    )}

                    {/* <Button variant="outlined" sx={{ padding: '0.7rem', marginRight: '1rem' }}>
                        <i className="fa-solid fa-bookmark"></i>
                    </Button>
                    <Button variant="outlined" sx={{ padding: '0.7rem', marginRight: '1rem' }}>
                        <i className="fa-solid fa-arrow-up-from-bracket"></i>
                    </Button> */}
                </Grid>
            </Grid>
            <Divider sx={{ marginTop: '2rem' }} />
            <Grid mt={'2rem'} container>
                {JobQuery.data?.jobDetail.city && JobQuery.data?.jobDetail.state && JobQuery.data?.jobDetail.country && (
                    <Grid item xs={12} lg={6}>
                        <p>
                            <i className="fa-solid fa-location-dot"></i> Company: {JobQuery.data?.organization?.name}
                        </p>
                    </Grid>
                )}
                {JobQuery.data?.jobDetail.city && JobQuery.data?.jobDetail.state && JobQuery.data?.jobDetail.country && (
                    <Grid item xs={12} lg={6}>
                        <p>
                            <i className="fa-solid fa-location-dot"></i> Location:{' '}
                            {`${JobQuery.data?.jobDetail.city}, ${JobQuery.data?.jobDetail.state}, ${JobQuery.data?.jobDetail.country}`}
                        </p>
                    </Grid>
                )}

                {(JobQuery.data?.jobDetail.workingArrangement || []).length > 0 && (
                    <Grid item xs={12} lg={6}>
                        <p>
                            <i className="fa-solid fa-briefcase"></i> Working Arrangement:{' '}
                            {JobQuery.data?.jobDetail.workingArrangement?.map((item) => (
                                <Chip key={item} label={item} sx={{ marginRight: '2px' }} />
                            ))}
                        </p>
                    </Grid>
                )}
                {(JobQuery.data?.jobDetail.employmentModal || []).length > 0 && (
                    <Grid item xs={12} lg={6}>
                        <p>
                            <i className="fa-solid fa-briefcase"></i> Employment Model(s):{' '}
                            {JobQuery.data?.jobDetail.employmentModal?.map((item) => (
                                <Chip key={item} label={item} sx={{ marginRight: '2px' }} />
                            ))}
                        </p>
                    </Grid>
                )}
                {JobQuery.data?.jobDetail.salaryRange && (
                    <Grid item xs={12} lg={6}>
                        <p>
                            <i className="fa-solid fa-hand-holding-dollar"></i> Salary: <Chip label={JobQuery.data?.jobDetail.salaryRange}></Chip>
                        </p>
                    </Grid>
                )}

                {JobQuery.data?.jobDetail.securityClearance && (
                    <Grid item xs={12} lg={6}>
                        <p>
                            <i className="fa-solid fa-shield"></i> Minimum Australian Security Clearance:{' '}
                            <Chip label={JobQuery.data?.jobDetail.securityClearance}></Chip>
                        </p>
                    </Grid>
                )}
                {JobQuery.data?.jobDetail.startDate && (
                    <Grid item xs={12} lg={6}>
                        <p>
                            <i className="fa-solid fa-shield"></i> StartDate: <Chip label={JobQuery.data?.jobDetail.startDate}></Chip>
                        </p>
                    </Grid>
                )}
                {JobQuery.data?.jobDetail.endDate && (
                    <Grid item xs={12} lg={6}>
                        <p>
                            <i className="fa-solid fa-shield"></i> End Date: <Chip label={JobQuery.data?.jobDetail.endDate}></Chip>
                        </p>
                    </Grid>
                )}
            </Grid>
            <Grid>
                <Typography variant="h6" color={'primary'}>
                    Role Description
                </Typography>
                <div dangerouslySetInnerHTML={{ __html: JobQuery.data?.jobDetail.roleDescription?.renderedHtml || '' }} />

                <Typography variant="h6" color={'primary'} mt={'2rem'}>
                    Key Requirements
                </Typography>
                <div dangerouslySetInnerHTML={{ __html: JobQuery.data?.jobDetail.keyRequirement?.renderedHtml || '' }} />

                {(JobQuery.data?.jobDetail?.skillInformation || []).length > 0 && (
                    <>
                        <Typography variant="h6" color={'primary'} mt={'2rem'}>
                            Desired Skills and Experience
                        </Typography>
                        <Typography>
                            Candidates should ensure their profiles include accurate and justifiable statements of competency and experience if any in the
                            following categories
                        </Typography>
                        <Grid container>
                            <ul>
                                {JobQuery.data?.jobDetail?.skillInformation?.map((item: any) => (
                                    <li key={item.display}>{item.display}</li>
                                ))}
                            </ul>
                        </Grid>
                    </>
                )}

                {!validateEditorState(JobQuery.data?.jobDetail.additionalDescriptionNotes?.editorState || '') && (
                    <>
                        <Typography variant="h6" color={'primary'} mt={'2rem'}>
                            Additional Information
                        </Typography>
                        <div dangerouslySetInnerHTML={{ __html: JobQuery.data?.jobDetail.additionalDescriptionNotes?.renderedHtml || '' }} />
                    </>
                )}
            </Grid>
            <Divider />
            <Grid>
                {!validateEditorState(JobQuery.data?.jobDetail.aboutCompany?.editorState || '') && (
                    <>
                        <Typography variant="h6" color={'primary'} mt={'2rem'}>
                            About {JobQuery.data?.organization?.name}
                        </Typography>
                        <div dangerouslySetInnerHTML={{ __html: JobQuery.data?.jobDetail.aboutCompany?.renderedHtml || '' }} />
                    </>
                )}
            </Grid>
            {/* move this back button to right  */}
            <Divider />
            <Grid display={'flex'} sx={{ marginTop: '1rem' }}>
                <Button
                    variant="contained"
                    style={{ marginLeft: 'auto' }}
                    onClick={() => {
                        navigate('../jobs');
                    }}
                >
                    Back
                </Button>
            </Grid>

            {open && (
                <GenericModal
                    successLabel="Update Profile"
                    handleSuccess={() => {
                        setOpen(false);
                        navigate('/my-profile');
                    }}
                    handleClose={() => setOpen(false)}
                >
                    <Typography>
                        You have already applied for this job. Employers will view the latest version of your profile. Please feel free to update your profile
                        here.
                    </Typography>
                </GenericModal>
            )}
        </>
    );
};

export default JobDetails;
