export function validateEditorState(editorState: string): boolean {
    try {
        const { root } = JSON.parse(editorState);
        if (root.children.length === 1 && root.children[0].children.length === 0) return true;
        return false;
    } catch (error) {
        return true;
    }
}
export function validateEditorString(value: string): boolean {
    try {
        const testIfJson = JSON.parse(value);
        if (typeof testIfJson == 'object') {
            return true;
        } else {
            return false;
        }
    } catch {
        return false;
    }
}
