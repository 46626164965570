import { getInstance } from '../../services/fetch';
import { Organisation } from '../../types/organisation';
import { AppDispatch } from '../store';
import { showErrorSnackbar } from './snackbarAction';

export const getOrganisations = () => async (dispatch: AppDispatch) => {
    try {
        const response = await getInstance().get<{ data: Organisation[]; error: string }>(`/public/organization/all`);
        dispatch({
            type: 'GET_ORGANISATIONS',
            payload: response.data,
        });
    } catch (e: any) {
        dispatch({
            type: 'GET_ORGANISATIONS_ERROR',
            payload: e?.message,
        });
    }
};
export const upsertOrganisation = (organisation: Organisation, logo?: File | null) => async (dispatch: AppDispatch) => {
    try {
        const { id } = organisation;
        await getInstance().post<{ error: string }, any>(`/admin/org`, organisation);
        if (logo) {
            const formData = new FormData();
            formData.append('file', logo);
            await getInstance().post<{ error: string }, any>(`/admin/org/${id}/upload`, formData, { isForm: true });
        }
        await getOrganisations()(dispatch);
    } catch (e: any) {
        dispatch({
            type: 'ORGANISATION_UPSERT_ERROR',
            payload: e?.message,
        });
        console.error('Organisation upsert error');
        dispatch(showErrorSnackbar(`An error occurred while upserting an organisation`));
    }
};

export const deleteAdminOrganisation = (orgId: string) => async (dispatch: AppDispatch) => {
    try {
        await getInstance().delete<{ error: string }, any>(`/admin/org/${orgId}`);
        await getOrganisations()(dispatch);
    } catch (e: any) {
        dispatch({
            type: 'ORGANISATION_DELETE_ERROR',
            payload: e?.message,
        });
        console.error('Organisation delete error');
        dispatch(showErrorSnackbar(`An error occurred while deleting an organisation`));
    }
};

export const getUserOrganization = () => async (dispatch: AppDispatch) => {
    try {
        const response = await getInstance().get<{ data: Organisation; error: string }>(`/employer/organization`);
        dispatch({ type: 'SET_USER_ORGANIZATION', payload: response.data });
    } catch (error) {
        dispatch(showErrorSnackbar(`An error occurred while getting users organisation`));
    }
};
