import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clone } from 'ramda';
import styled from 'styled-components';
import { Box, ToggleButtonGroup, ToggleButton, Typography, Divider, Button } from '@mui/material';
import { Profile as CandidateProfile } from '../../types/CandidateProfile';
import { setActiveStep, upsertProfile, commenceTestDrive } from '../../redux/actions/profileAction';
import { selectProfileData } from '../../redux/selectors/profileSelector';
import { selectIsUserEmployer } from '../../redux/selectors/userSelector';
import { PROFILE_STEPS } from '../../data/profileStepperData';

export const CustomButtonGroup = styled(ToggleButtonGroup)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    width: 30rem;
`;

export const CustomToggleButton = styled(ToggleButton)`
    color: #1b717a;
    border: 0.063rem solid #1b717a !important;
    border-radius: 0.313rem !important;

    &.Mui-selected,
    &.Mui-selected:hover {
        color: #fff;
        background-color: #1b717a;
    }
`;

export const FullWidthToggleButton = styled(CustomToggleButton)`
    width: 100%;
`;

const pages = PROFILE_STEPS.map((step) => step.page);
const activeStep = pages.indexOf('/accountType') + 1;
const nextPage = pages[activeStep];
const previousPage = pages[activeStep - 2];

const AccountType = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const reduxProfileDetails = useSelector(selectProfileData);
    const isUserEmployer = useSelector(selectIsUserEmployer);

    const [profileDetails, setProfileDetails] = useState<CandidateProfile>(reduxProfileDetails);

    useEffect(() => {
        setProfileDetails(clone(reduxProfileDetails));
    }, [reduxProfileDetails, setProfileDetails]);

    const handleChange = (_event: React.MouseEvent<HTMLElement>, newType: 'jobseeker' | 'employer') => {
        setProfileDetails({ ...profileDetails, accountType: newType });
    };

    const handleFinish = () => {
        const { firstname, surname, email, accountType, phoneNumber: phone } = profileDetails;

        dispatch(upsertProfile({ accountType, wizardCompleted: true }));
        window.open('http://www.aukusjobs.com/employer-welcome', '_blank');

        if (!isUserEmployer) {
            dispatch(
                commenceTestDrive({
                    firstname,
                    surname,
                    email,
                    phone,
                })
            );
        }

        navigate('/candidate/search');
    };

    const onPrevious = () => {
        navigate(previousPage);
    };

    const onSubmit = () => {
        dispatch(upsertProfile({ accountType: profileDetails.accountType }));
        dispatch(setActiveStep(activeStep));
        navigate(nextPage);
    };

    return (
        <>
            <Divider />
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={10}>
                <Typography fontSize="2rem">Are you a ...</Typography>
                <CustomButtonGroup color="primary" value={profileDetails.accountType} onChange={handleChange} aria-label="Medium sizes" exclusive={true}>
                    <FullWidthToggleButton value="jobseeker" key="jobseeker">
                        <Typography variant="h3">Jobseeker</Typography>
                    </FullWidthToggleButton>
                    <Typography fontSize="2rem" color="#000">
                        or
                    </Typography>
                    <FullWidthToggleButton value="employer" key="employer">
                        <Typography variant="h3">Employer</Typography>
                    </FullWidthToggleButton>
                </CustomButtonGroup>
                <Box display="flex" justifyContent="space-between" alignItems="center" m="1rem 0.5rem" width="100%">
                    <Button variant="outlined" onClick={onPrevious}>
                        Previous
                    </Button>
                    {profileDetails.accountType === 'employer' ? (
                        <Button variant="contained" onClick={handleFinish}>
                            Finish
                        </Button>
                    ) : (
                        <Button variant="contained" onClick={onSubmit} disabled={profileDetails.accountType === 'EMPTY'}>
                            Next
                        </Button>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default AccountType;
