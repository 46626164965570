import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Divider, Button, Grid, Backdrop, CircularProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CommonModal from '../../../../../components/CommonModal';
import { showErrorSnackbar } from '../../../../../redux/actions/snackbarAction';
import { upsertProfile } from '../../../../../redux/actions/profileAction';
import { deleteFile, getFiles, uploadFile, downloadMyFile } from '../../../../../redux/actions/filesAction';
import { selectProfileWizardCompleted } from '../../../../../redux/selectors/profileSelector';
import { selectFilesData, selectIsLoading } from '../../../../../redux/selectors/filesSelector';
import { GenericModal } from '../../../../../components/modal';
import { useNavigate } from 'react-router-dom';

type ProfileAttachmentProps = {
    stepper: boolean;
    next: () => void;
    prev: () => void;
};

function Attachments({ stepper, next, prev }: ProfileAttachmentProps) {
    const dispatch = useDispatch();
    const uploadedFiles = useSelector(selectFilesData);
    const isLoading = useSelector(selectIsLoading);
    const wizardCompleted = useSelector(selectProfileWizardCompleted);
    const navigate = useNavigate();
    const [isBlocking, setIsBlocking] = useState<boolean>(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [open, setOpen] = useState<boolean>(wizardCompleted ? false : true);
    const [conformation, setConformation] = useState<boolean>(true);

    useEffect(() => {
        dispatch(getFiles());
    }, [dispatch]);

    // usePrompt('You have unsaved changes, are you sure you want to leave?', isBlocking);

    function selectFile(event: any) {
        setSelectedFiles(event.target.files);
        setIsBlocking(true);
    }

    function upload() {
        const currentFile = selectedFiles[0];

        const formData = new FormData();
        formData.append('filename', (currentFile as any).name);
        formData.append('file', currentFile);
        // setCurrentFile(currentFile);
        dispatch(uploadFile(formData));
        setSelectedFiles([]);
        setIsBlocking(false);
    }

    function onRemove() {
        // setCurrentFile(undefined);
        setSelectedFiles([]);
        setIsBlocking(false);
    }

    const handleToggleModal = () => {
        setOpen(!open);
    };

    const handlePrevious = () => {
        prev();
    };

    const handleFinish = () => {
        if (isBlocking) {
            dispatch(showErrorSnackbar('You have unsaved changes. Please upload the file or remove it.'));
            return;
        }
        dispatch(upsertProfile({ wizardCompleted: true }));
        next();
    };
    return (
        <React.Fragment>
            {wizardCompleted && (
                <Typography variant="h4" p={2}>
                    Attachments
                </Typography>
            )}
            <Divider></Divider>
            <form>
                <Grid sx={{ flexGrow: 1 }} container spacing={4} padding={1} alignContent="space-around">
                    {/* Row 1 */}
                    <Grid item xs={8}>
                        <Button variant="contained" component="label">
                            Step 1 - Select File
                            <input hidden type="file" onChange={selectFile} />
                        </Button>
                        <label htmlFor="btn-upload"></label>
                    </Grid>
                    <Grid item xs={4} />

                    {/* Row 2 */}
                    <Grid item xs={8}>
                        <span>{selectedFiles && selectedFiles.length > 0 ? (selectedFiles[0] as any).name : null}</span>
                        {selectedFiles && selectedFiles.length > 0 ? (
                            <IconButton aria-label="delete" onClick={onRemove}>
                                <DeleteIcon />
                            </IconButton>
                        ) : null}
                    </Grid>
                    <Grid item xs={4} />

                    {/* Row 3 */}
                    <Grid item xs={8}>
                        <Button variant="contained" disabled={!(selectedFiles.length > 0)} onClick={upload}>
                            Step 2 - Upload File
                        </Button>
                    </Grid>
                    <Grid item xs={4} />

                    {/* Row 4 */}
                    <Grid item xs={8}>
                        <Typography variant="h6" className="list-header">
                            List of Uploaded Files
                        </Typography>
                        <Grid sx={{ flexGrow: 1 }} container spacing={1} padding={1} justifyContent="flex-start" alignItems="center">
                            <List>
                                {uploadedFiles.map((file, idx) => (
                                    <React.Fragment key={idx}>
                                        <ListItem>
                                            <ListItemText primary={(file as any).name} />
                                            <Button
                                                onClick={() => dispatch(downloadMyFile((file as any).uuid))}
                                                variant="outlined"
                                                sx={{ m: 1 }}
                                                startIcon={<DownloadIcon />}
                                            >
                                                Download
                                            </Button>
                                            <Button
                                                onClick={() => dispatch(deleteFile((file as any).uuid))}
                                                variant="outlined"
                                                sx={{ m: 1 }}
                                                startIcon={<DeleteIcon />}
                                            >
                                                Remove
                                            </Button>
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
                {stepper && (
                    <Grid item xs={12} sx={{ display: 'flex', placeContent: 'end' }}>
                        {
                            <>
                                <div></div>
                                <div>
                                    <Button onClick={handlePrevious} variant="outlined">
                                        Previous
                                    </Button>

                                    <Button onClick={handleFinish} variant="contained" style={{ marginLeft: '5px' }}>
                                        Next
                                    </Button>
                                </div>
                            </>
                        }
                    </Grid>
                )}
            </form>
            <CommonModal open={open} buttonLabel="Let's go" title="ADD ANY ATTACHMENTS (Optional)" handleClick={handleToggleModal}>
                <Typography variant="body2">Please attach your CV and any relevant certificates to your profile.</Typography>
            </CommonModal>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {conformation && (
                <GenericModal
                    handleClose={() => {
                        navigate('./../');
                        setConformation(true);
                    }}
                    handleSuccess={() => {
                        setConformation(false);
                    }}
                    successLabel="ok"
                    cancelLabel="Cancel Application"
                >
                    <Typography variant="h6">Please ensure you have attached your latest CV and any other relevant documents.</Typography>
                </GenericModal>
            )}
        </React.Fragment>
    );
}

export default Attachments;
