import { getInstance } from '../../services/fetch';
import { Profile as CandidateProfile } from '../../types/CandidateProfile';
import { AppDispatch } from '../store';
import { showErrorSnackbar, showSuccessSnackbar } from './snackbarAction';
import { getErrorMessage } from '../../utils/getErrorMessage';
import { logout } from './userAction';

export const setActiveStep = (activeStep: number) => (dispatch: AppDispatch) => {
    dispatch({
        type: 'SET_ACTIVE_STEP',
        payload: activeStep,
    });
};

export const upsertProfile = (profile: CandidateProfile, adminEdit?: boolean, message?: JSX.Element) => async (dispatch: AppDispatch) => {
    try {
        const profileKeys = Object.keys(profile);
        await getInstance().put<{ error: string }, any>(`/seeker/profile`, { profile, adminEdit });
        await getProfile()(dispatch);

        if (profileKeys.length === 1 && (profileKeys[0] === 'email' || profileKeys[0] === 'requestedEmployerAccess')) {
            return;
        }

        if (profileKeys.length === 1 && profileKeys[0] === 'wizardCompleted') {
            dispatch(setCongratsMessage(true));
            return;
        }

        if (message) {
            dispatch(showSuccessSnackbar(message));
            return;
        }

        dispatch(showSuccessSnackbar('Saved successfully!'));
    } catch (e) {
        dispatch({
            type: 'PROFILE_UPDATE_ERROR',
            payload: getErrorMessage(e),
        });
        console.error('Profile error');
        dispatch(showErrorSnackbar(`Profile upsert error`));
    }
};

export const getProfile = () => async (dispatch: AppDispatch) => {
    try {
        const response = await getInstance().get<{ data: CandidateProfile; error: string }>(`/seeker/profile`);

        dispatch({
            type: 'GET_PROFILE',
            payload: response.data,
        });
    } catch (e) {
        dispatch({
            type: 'GET_PROFILE_ERROR',
            payload: getErrorMessage(e),
        });
        console.error('Profile error');
        dispatch(showErrorSnackbar(`Get profile error`));
    }
};

export const setCongratsMessage = (value: boolean) => (dispatch: AppDispatch) => {
    dispatch({
        type: 'CONGRATS_MESSAGE',
        payload: value,
    });
};

export const requestSubscription =
    (employer: Partial<{ firstname: string; surname: string; email: string; phone: string }>) => async (dispatch: AppDispatch) => {
        try {
            await getInstance().post<{ error: string }, any>(`/employer/email/subscription`, employer);
            dispatch(upsertProfile({ requestedEmployerAccess: true }));
        } catch (e) {
            dispatch(showErrorSnackbar(`Request subscription error. Please try again later.`));
        }
    };

export const commenceTestDrive = (employer: Partial<{ firstname: string; surname: string; email: string; phone: string }>) => async (dispatch: AppDispatch) => {
    try {
        await getInstance().post<{ error: string }, any>(`/employer/email/test/drive`, employer);
    } catch (e) {
        dispatch(showErrorSnackbar(`Commence test drive error. Please try again later.`));
    }
};

export const deleteProfile = () => async (dispatch: AppDispatch) => {
    try {
        await getInstance().delete<{ error: string }, any>('/seeker/profile');
        dispatch(logout(true));
    } catch {
        dispatch(showErrorSnackbar(`Delete profile error. Please try again later.`));
    }
};
