import { FC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

interface CommonModalProps {
    open: boolean;
    buttonLabel: string;
    title?: string;
    children: JSX.Element;
    handleClick: () => void;
}

const CommonModal: FC<CommonModalProps> = ({ open, children, buttonLabel, title, handleClick }) => {
    return (
        <Dialog open={open} fullWidth>
            <DialogTitle sx={{ textAlign: 'center' }}>
                {/* <InfoOutlined fontSize="large" color="primary" /> */}
                <Typography fontSize={title === 'CONGRATULATIONS' ? '2rem' : '1rem'} fontWeight="bold" color={'primary'}>
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleClick}>
                    {buttonLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CommonModal;
