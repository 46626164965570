import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stepper, Step, StepLabel, Typography } from '@mui/material';
import CommonModal from '../CommonModal';
import { selectProfileActiveStep, selectProfileData } from '../../redux/selectors/profileSelector';
import { selectSkillsAnswers } from '../../redux/selectors/skillsSelector';
import { setActiveStep } from '../../redux/actions/profileAction';
import { PROFILE_STEPS } from '../../data/profileStepperData';

const ProfileStepper = () => {
    const dispatch = useDispatch();
    const activeStep = useSelector(selectProfileActiveStep);
    const profileData = useSelector(selectProfileData);
    const skills = useSelector(selectSkillsAnswers);

    const { firstname, surname, email, accountType, readiness, auCitizenshipStatus, wizardCompleted } = profileData;
    const [open, setOpen] = useState<boolean>(true);

    const handleClick = () => {
        setOpen(!open);
    };

    useEffect(() => {
        if (!firstname || !surname || !email) {
            dispatch(setActiveStep(0));
            return;
        }

        if (accountType === 'EMPTY') {
            dispatch(setActiveStep(1));
            return;
        }

        if (!auCitizenshipStatus || readiness === 'EMPTY') {
            dispatch(setActiveStep(2));
            return;
        }

        if (Object.keys(skills).length === 0) {
            dispatch(setActiveStep(3));
            return;
        }

        dispatch(setActiveStep(4));
    }, [firstname, surname, email, accountType, readiness, skills, auCitizenshipStatus, dispatch]);

    if (wizardCompleted) {
        return null;
    }

    return (
        <>
            <Stepper activeStep={activeStep} sx={{ mb: 3 }} alternativeLabel>
                {PROFILE_STEPS.map((step) => {
                    // if (index === 2 && accountType === 'employer') {
                    //     step.label = 'Test';
                    // }
                    return (
                        <Step
                            key={step.label}
                            sx={{
                                '& .MuiStepLabel-label': {
                                    fontSize: '1.375rem', // Just text label
                                },
                            }}
                        >
                            <StepLabel>{step.label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <CommonModal open={open} buttonLabel="Let's go" handleClick={handleClick}>
                <>
                    <Typography>
                        <b>NEW USERS</b> - Thank you for registering with AUKUS Jobs. Please complete the following account details and click on the NEXT button
                        to move to the next step.
                    </Typography>
                    <br />
                    <Typography>
                        <b>EXISTING USERS</b> - Some of our features have been upgraded and require your input. Please take a short moment to reconfirm your
                        existing settings and add a few new ones.
                    </Typography>
                </>
            </CommonModal>
        </>
    );
};

export default ProfileStepper;
