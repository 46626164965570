import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import theme from './theme';
import './theme.scss';
import ToolbarPlugin from './plugins/Toolbarplugin';
import { ListItemNode, ListNode } from '@lexical/list';
import { validateEditorString } from './utils/validator';
import { generateTextNodeWithString } from './utils';
import { $generateHtmlFromNodes } from '@lexical/html';

function onError(error: any) {
    console.error(error);
}

const defaultValue =
    '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';
interface EditorProps {
    onChange: (event: any) => void;
    placeholder?: string;
    value?: string;
    label?: string;
    error?: boolean;
    required?: boolean;
    disabled?: boolean;
    name: string;
}

function Placeholder(props: any) {
    const { placeholder } = props;
    return <div className="editor-placeholder">{placeholder}</div>;
}
function Editor({ onChange, placeholder, name, value = defaultValue, label, error = false, required = false, disabled = false }: EditorProps) {
    const isValid = validateEditorString(value);
    // remove all the control characters
    // eslint-disable-next-line no-control-regex
    value = value.replace(/[\u0000-\u001F\u007F-\u009F\u061C\u200E\u200F\u202A-\u202E\u2066-\u2069]/g, '');
    value = isValid ? value : generateTextNodeWithString(value);
    const editorConfig = {
        namespace: 'Aukus-Editor',
        theme,
        onError,
        editorState: value,
        nodes: [ListNode, ListItemNode],
        editable: !disabled,
    };

    return (
        <LexicalComposer initialConfig={editorConfig}>
            <div className={`editor-container ${error ? 'error' : ''}`}>
                <ToolbarPlugin />
                {label && (
                    <label className={`editor-label ${error ? 'error' : ''}`}>
                        {label} {required ? '*' : ''}
                    </label>
                )}
                <div className="editor-inner">
                    <RichTextPlugin
                        contentEditable={<ContentEditable className="editor-input" id="aukus-editor" />}
                        placeholder={<Placeholder placeholder={placeholder} />}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <OnChangePlugin
                        onChange={(value, editor) => {
                            let renderedHtml;
                            value.read(() => {
                                renderedHtml = $generateHtmlFromNodes(editor, null);
                            });
                            onChange({ target: { name, value: { renderedHtml, editorState: JSON.stringify(value) } } });
                        }}
                    />
                    <ListPlugin />
                    <HistoryPlugin />
                </div>
            </div>
        </LexicalComposer>
    );
}

export default Editor;
