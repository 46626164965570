import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Typography, Divider, Button, Grid, Backdrop, CircularProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CommonModal from '../../../components/CommonModal';
import { showErrorSnackbar } from '../../../redux/actions/snackbarAction';
import { setActiveStep, upsertProfile } from '../../../redux/actions/profileAction';
import { deleteFile, getFiles, uploadFile, downloadMyFile } from '../../../redux/actions/filesAction';
import { selectProfileWizardCompleted } from '../../../redux/selectors/profileSelector';
import { selectFilesData, selectIsLoading } from '../../../redux/selectors/filesSelector';
import { PROFILE_STEPS } from '../../../data/profileStepperData';

const pages = PROFILE_STEPS.map((step) => step.page);
const activeStep = pages.indexOf('/files') + 1;
const previousPage = pages[activeStep - 2];

function Attachments() {
    const dispatch = useDispatch();
    const uploadedFiles = useSelector(selectFilesData);
    const isLoading = useSelector(selectIsLoading);
    const wizardCompleted = useSelector(selectProfileWizardCompleted);

    const [isBlocking, setIsBlocking] = useState<boolean>(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [open, setOpen] = useState<boolean>(wizardCompleted ? false : true);

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getFiles());
    }, [dispatch]);

    // usePrompt('You have unsaved changes, are you sure you want to leave?', isBlocking);

    function selectFile(event: any) {
        setSelectedFiles(event.target.files);
        setIsBlocking(true);
    }

    function upload() {
        const currentFile = selectedFiles[0];

        const formData = new FormData();
        formData.append('filename', (currentFile as any).name);
        formData.append('file', currentFile);
        // setCurrentFile(currentFile);
        dispatch(uploadFile(formData));
        setSelectedFiles([]);
        setIsBlocking(false);
    }

    function onRemove() {
        // setCurrentFile(undefined);
        setSelectedFiles([]);
        setIsBlocking(false);
    }

    const handleToggleModal = () => {
        setOpen(!open);
    };

    const handlePrevious = () => {
        navigate(previousPage);
    };

    const handleFinish = () => {
        if (isBlocking) {
            dispatch(showErrorSnackbar('You have unsaved changes. Please upload the file or remove it.'));
            return;
        }
        dispatch(upsertProfile({ wizardCompleted: true }));
        dispatch(setActiveStep(activeStep));
        window.open('https://www.aukusjobs.com/jobseeker-welcome', '_blank');
        navigate('/my-profile');
    };

    return (
        <React.Fragment>
            {wizardCompleted && (
                <Typography variant="h4" p={2}>
                    Attachments
                </Typography>
            )}
            <Divider></Divider>
            <form>
                <Grid sx={{ flexGrow: 1 }} container spacing={4} padding={1} alignContent="space-around">
                    {/* Row 1 */}
                    <Grid item xs={8}>
                        <Button variant="contained" component="label">
                            Step 1 - Select File
                            <input hidden type="file" onChange={selectFile} />
                        </Button>
                        <label htmlFor="btn-upload"></label>
                    </Grid>
                    <Grid item xs={4} />

                    {/* Row 2 */}
                    <Grid item xs={8}>
                        <span>{selectedFiles && selectedFiles.length > 0 ? (selectedFiles[0] as any).name : null}</span>
                        {selectedFiles && selectedFiles.length > 0 ? (
                            <IconButton aria-label="delete" onClick={onRemove}>
                                <DeleteIcon />
                            </IconButton>
                        ) : null}
                    </Grid>
                    <Grid item xs={4} />

                    {/* Row 3 */}
                    <Grid item xs={8}>
                        <Button variant="contained" disabled={!(selectedFiles.length > 0)} onClick={upload}>
                            Step 2 - Upload File
                        </Button>
                    </Grid>
                    <Grid item xs={4} />

                    {/* Row 4 */}
                    <Grid item xs={8}>
                        <Typography variant="h6" className="list-header">
                            List of Uploaded Files
                        </Typography>
                        <Grid sx={{ flexGrow: 1 }} container spacing={1} padding={1} justifyContent="flex-start" alignItems="center">
                            <List>
                                {uploadedFiles.map((file, idx) => (
                                    <React.Fragment key={idx}>
                                        <ListItem>
                                            <ListItemText primary={(file as any).name} />
                                            <Button
                                                onClick={() => dispatch(downloadMyFile((file as any).uuid))}
                                                variant="outlined"
                                                sx={{ m: 1 }}
                                                startIcon={<DownloadIcon />}
                                            >
                                                Download
                                            </Button>
                                            <Button
                                                onClick={() => dispatch(deleteFile((file as any).uuid))}
                                                variant="outlined"
                                                sx={{ m: 1 }}
                                                startIcon={<DeleteIcon />}
                                            >
                                                Remove
                                            </Button>
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    sx={{ flexGrow: 1, pl: 3, pt: 2 }}
                    container
                    spacing={2}
                    padding={1}
                    alignContent="space-between"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    {!wizardCompleted && (
                        <>
                            <Grid item sm={1}>
                                <Button onClick={handlePrevious} variant="outlined">
                                    Previous
                                </Button>
                            </Grid>
                            <Grid item sm={1.5}>
                                <Button onClick={handleFinish} variant="contained">
                                    Finish Setup
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
                <br />
            </form>
            <CommonModal open={open} buttonLabel="Let's go" title="ADD ANY ATTACHMENTS (Optional)" handleClick={handleToggleModal}>
                <Typography variant="body2">Please attach your CV and any relevant certificates to your profile.</Typography>
            </CommonModal>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </React.Fragment>
    );
}

export default Attachments;
