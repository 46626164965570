import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { clone } from 'ramda';
import { Box, Typography, Divider, Grid, TextField, Button } from '@mui/material';
import DeleteAccountModal from '../../components/DeleteAccountModal';
import TakeABreakModal from '../../components/TakeABreakModal';
import { Profile as CandidateProfile } from '../../types/CandidateProfile';
import { setActiveStep, upsertProfile, deleteProfile } from '../../redux/actions/profileAction';
import { getSkillsAnswers } from '../../redux/actions/skillsAction';
import { getFiles } from '../../redux/actions/filesAction';
import { showErrorSnackbar } from '../../redux/actions/snackbarAction';
import { selectProfileData } from '../../redux/selectors/profileSelector';
import { PROFILE_STEPS } from '../../data/profileStepperData';
import { profileReadiness } from '../../data/dropDownData';
import { mapValueToLabel } from '../../utils/mapValueToLabel';
import { parseValue } from '../../utils/parseValue';
import { LIST_MAP, HELPER_TEXT } from '../../fixtures';

const REQUIRED_FIELDS_MAP = {
    firstname: false,
    surname: false,
    email: false,
};

const pages = PROFILE_STEPS.map((step) => step.page);
const activeStep = pages.indexOf('/account') + 1;
const nextPage = pages[activeStep];

const CustomButton = styled(Button)`
    background-color: #d32f2f;

    &:hover {
        background-color: #b30000;
    }
`;

const Account = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const reduxProfileDetails = useSelector(selectProfileData);
    const [openTakeABreakModal, setOpenTakeABreakModal] = useState<boolean>(false);
    const [openDeleteAccountModal, setOpenDeleteAccountModal] = useState<boolean>(false);
    const [profileDetails, setProfileDetails] = useState<CandidateProfile>(reduxProfileDetails);
    const [requiredFields, setRequiredFields] = useState<typeof REQUIRED_FIELDS_MAP>(REQUIRED_FIELDS_MAP);

    const handleProfileDetailsChange = async (event: { target: { name: string; value: any; checked?: any } }) => {
        const { name, value } = event.target;

        const newVal = parseValue(value);
        const nameArr = name.split('.');
        let newProfileDetails = {};
        if (nameArr.length === 2) {
            const [parent, child] = nameArr;
            newProfileDetails = {
                ...profileDetails,
                [parent]: {
                    ...(profileDetails as any)[parent],
                    [child]: newVal,
                },
            };
        } else {
            newProfileDetails = {
                ...profileDetails,
                [name]: newVal,
            };
        }
        setProfileDetails(newProfileDetails);
    };

    const onSubmit = async () => {
        const { firstname, surname, email } = profileDetails;

        if (!firstname || !surname || !email) {
            setRequiredFields({
                firstname: !firstname,
                surname: !surname,
                email: !email,
            });

            dispatch(showErrorSnackbar('Mandatory fields missing!'));

            return;
        }

        const payload = {
            ...profileDetails,
        };

        dispatch(upsertProfile(payload));

        setRequiredFields(REQUIRED_FIELDS_MAP);

        if (!profileDetails.wizardCompleted) {
            dispatch(setActiveStep(activeStep));
            navigate(nextPage);
        }
    };

    const handleOpenTakeABreakModal = () => {
        setOpenTakeABreakModal(true);
    };

    const handleTakeABreak = () => {
        setOpenTakeABreakModal(false);

        const message = (
            <div>
                Your profile has been saved as <b>{mapValueToLabel(profileReadiness, 'NOT_SEEKING' || '')}</b>
                <ul>
                    <li>Your skills - {LIST_MAP['NOT_SEEKING' as keyof typeof LIST_MAP].skills}</li>
                    <li>Your identity - {LIST_MAP['NOT_SEEKING' as keyof typeof LIST_MAP].identity}</li>
                </ul>
            </div>
        );

        dispatch(upsertProfile({ readiness: 'NOT_SEEKING' }, false, message));
        navigate('/profile');
    };

    const handleProceedDeleteModal = () => {
        setOpenDeleteAccountModal(true);
        setOpenTakeABreakModal(false);
    };

    const handleCloseDeleteAccountModal = () => {
        setOpenDeleteAccountModal(false);
    };

    const handleDeleteAccount = async () => {
        dispatch(deleteProfile());
        setOpenDeleteAccountModal(false);
        setOpenTakeABreakModal(false);
    };

    useEffect(() => {
        setProfileDetails(clone(reduxProfileDetails));
    }, [reduxProfileDetails, setProfileDetails]);

    useEffect(() => {
        dispatch(getSkillsAnswers());
        dispatch(getFiles());
    }, [dispatch]);

    return (
        <>
            {profileDetails.wizardCompleted && (
                <Typography variant="h4" p={2}>
                    Account Details
                </Typography>
            )}
            <Divider></Divider>
            {/* Row 1 */}
            <Box mt={5}>
                <Grid
                    sx={{ flexGrow: 1, pl: 3, pt: 2.25 }}
                    container
                    spacing={2}
                    padding={1}
                    alignContent="space-around"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item sm={12} md={6}>
                        <TextField
                            label="First Name"
                            onChange={handleProfileDetailsChange}
                            name="firstname"
                            value={profileDetails.firstname}
                            error={requiredFields.firstname}
                            helperText={requiredFields.firstname ? HELPER_TEXT : ''}
                            FormHelperTextProps={{ style: { fontSize: '0.875rem' } }}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <TextField
                            label="Last Name"
                            onChange={handleProfileDetailsChange}
                            name="surname"
                            value={profileDetails.surname}
                            error={requiredFields.surname}
                            helperText={requiredFields.surname ? HELPER_TEXT : ''}
                            FormHelperTextProps={{ style: { fontSize: '0.875rem' } }}
                            required
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid
                    sx={{ flexGrow: 1, pl: 3, pt: 2.25 }}
                    container
                    spacing={2}
                    padding={1}
                    alignContent="space-around"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item sm={12} md={6}>
                        <TextField
                            label="Contact Email (Can be different to your login email) "
                            onChange={handleProfileDetailsChange}
                            name="email"
                            value={profileDetails.email}
                            error={requiredFields.email}
                            helperText={requiredFields.email ? HELPER_TEXT : ''}
                            FormHelperTextProps={{ style: { fontSize: '0.875rem' } }}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <TextField label="Phone Number" onChange={handleProfileDetailsChange} name="phoneNumber" value={profileDetails.phoneNumber} fullWidth />
                    </Grid>
                </Grid>
                <Grid
                    sx={{ flexGrow: 1, pl: 3, pt: 2.25 }}
                    container
                    spacing={2}
                    padding={1}
                    alignContent="space-around"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item sm={12} md={6}></Grid>
                    <Grid item sm={12} md={6}>
                        <TextField label="Candidate ID" value={profileDetails.candidateId} disabled fullWidth />
                    </Grid>
                </Grid>
            </Box>
            <Box display="flex" justifyContent="flex-end" alignItems="center" m="1rem 0.5rem">
                <Button onClick={onSubmit} variant="contained">
                    {profileDetails.wizardCompleted ? 'Save' : 'Next'}
                </Button>
            </Box>
            {profileDetails.wizardCompleted && (
                <>
                    <Typography variant="h4" p={2}>
                        Account Settings
                    </Typography>
                    <Divider></Divider>
                    <Box mt={5} pl={3}>
                        <CustomButton
                            variant="contained"
                            onClick={profileDetails.accountType === 'jobseeker' ? handleOpenTakeABreakModal : handleProceedDeleteModal}
                        >
                            Delete my account
                        </CustomButton>
                    </Box>
                    <TakeABreakModal
                        open={openTakeABreakModal}
                        handleTakeABreak={handleTakeABreak}
                        handleProceedDelete={handleProceedDeleteModal}
                        onCancel={() => {
                            setOpenTakeABreakModal(false);
                        }}
                    />
                    <DeleteAccountModal
                        open={openDeleteAccountModal}
                        handleCloseModal={handleCloseDeleteAccountModal}
                        handleDeleteProfile={handleDeleteAccount}
                    />
                </>
            )}
        </>
    );
};

export default Account;
