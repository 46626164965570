import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clone } from 'ramda';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Select,
    MenuItem,
    FormControlLabel,
    TextField,
    DialogActions,
    Grid,
    Divider,
    Typography,
    Stack,
    Chip,
    Checkbox,
    IconButton,
    Box,
    FormControl,
    OutlinedInput,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, AddCircle, RemoveCircle, Close as CloseIcon } from '@mui/icons-material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import DoneSharpIcon from '@mui/icons-material/DoneSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { AdminSkill, Skill } from '../../../types/skill';
import { getAdminSkillsFramework, upsertSkill, deleteAdminSkill } from '../../../redux/actions/adminAction';
import { selectAdminSkillsData } from '../../../redux/selectors/skillsSelector';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../redux/actions/snackbarAction';

const AdminSkills = () => {
    const DEFAULT_SKILL_FRAMEWORK: { [key: string]: { display: string } } = {
        '0100': { display: 'Aspirant - Not yet skilled or experienced' },
        '0200': { display: 'Certificate I' },
        '0300': { display: 'Certificate II' },
        '0400': { display: 'Certificate III' },
        '0500': { display: 'Certificate IV' },
        '0600': { display: 'AQF Level 5 - Diploma' },
        '0700': { display: 'Advanced Diploma, Associate Degree' },
        '0800': { display: 'Bachelor Degree' },
        '0900': { display: 'Bachelor Honours Degree, Graduate Certificate, Graduate Diploma' },
        '1000': { display: 'Masters Degree' },
        '1100': { display: 'Doctoral Degree' },
    };

    const DEFAULT_COMPETENCY_BRACKETS_SELF_ASSESS: { [key: string]: { display: string } } = {
        '0100': { display: 'ASPIRANT - Not yet skilled or experienced.' },
        '0300': { display: 'LEARNER - I have limited understanding and am building my skills.' },
        '0500': { display: 'BASIC - I have some familiarity, but require frequent support.' },
        '0700': { display: 'INTERMEDIATE - I have a working knowledge and can work unsupervised.' },
        '0900': { display: 'ADVANCED - I am experienced in this and am able to assist, consult or lead others.' },
        '1100': { display: 'EXPERT - I have a deep understanding. I am recognised as being an authority, and could teach this subject.' },
    };

    const DEFAULT_COMPETENCY_BRACKETS_HYBRID: { [key: string]: { display: string } } = {
        '0100': { display: 'ASPIRANT - not yet skilled or experienced' },
        '0200': { display: 'Certificate I' },
        '0300': { display: 'Certificate II …or... LEARNER - I have limited understanding and am building my skills.' },
        '0400': { display: 'Certificate III' },
        '0500': { display: 'Certificate IV …or... BASIC - I have some familiarity, but require frequent support.' },
        '0600': { display: 'AQF Level 5 – Diploma' },
        '0700': { display: 'Advanced Diploma, Associate Degree …or... INTERMEDIATE - I have a working knowledge and can work unsupervised.' },
        '0800': { display: 'Bachelor Degree' },
        '0900': {
            display:
                'Bachelor Honours Degree, Graduate Certificate, Graduate Diploma …or... ADVANCED - I am experienced in this and am able to assist, consult or lead others.',
        },
        '1000': { display: 'Masters Degree' },
        '1100': {
            display: 'Doctoral Degree …or... EXPERT - I have a deep understanding. I am recognised as being an authority, and could teach this subject.',
        },
    };

    const DEFAULT_COMPETENCY_BRACKETS_LANGUAGE: { [key: string]: { display: string } } = {
        '0100': { display: 'ASPIRANT - not yet skilled or experienced' },
        '0200': { display: 'LEVEL 1 - NON USER. I have no ability to use the language except a few isolated words.' },
        '0300': { display: 'LEVEL 2 - INTERMITTENT USER. I have great difficulty understanding spoken and written language.' },
        '0400': {
            display:
                'LEVEL 3 - EXTREMELY LIMITED USER. I convey and understand only general meaning in very familiar situations. I have frequent breakdowns in communication.',
        },
        '0500': {
            display:
                'LEVEL 4 - LIMITED USER. My basic competence is limited to familiar situations. I frequently experience problems in understanding and expression. I am not able to use complex language.',
        },
        '0600': {
            display:
                'LEVEL 5 - MODEST USER. I have a partial command of the language and I cope with overall meaning in most situations, although I am likely to make many mistakes. I should be able to handle basic communication in my own field.',
        },
        '0700': {
            display:
                'LEVEL 6 - COMPETENT USER. I have an effective command of the language despite some inaccuracies, inappropriate usage and misunderstandings. I can use and understand fairly complex language, particularly in familiar situations.',
        },
        '0800': {
            display:
                'LEVEL 7. GOOD USER. I have operational command of the language, though with occasional inaccuracies, inappropriate usage and misunderstandings in some situations. I can generally handle complex language well and understand detailed reasoning.',
        },
        '0900': {
            display:
                'LEVEL 8 - VERY GOOD USER. I have full operational command of the language with only occasional unsystematic inaccuracies and inappropriate usage. I may misunderstand some things in unfamiliar situations. I can handle complex and detailed argumentation well.',
        },
        '1100': {
            display:
                'LEVEL 9 - EXPERT USER. I have full operational command of the language. My use of the language is appropriate, accurate and fluent, and shows complete understanding.',
        },
    };

    const DEFAULT_COMPETENCY_BRACKETS_GEOGRAPHY: { [key: string]: { display: string } } = {
        '0600': { display: 'This is a location I would consider' },
        '1100': { display: 'This is a desired location' },
    };

    const DEFAULT_SKILL_SCORES: { [key: string]: number } = {
        '0100': 5,
        '0200': 10,
        '0300': 20,
        '0400': 30,
        '0500': 40,
        '0600': 50,
        '0700': 60,
        '0800': 70,
        '0900': 80,
        '1000': 90,
        '1100': 100,
    };

    const DEFAULT_EXPERIENCE_BRACKETS_20YR: { [key: string]: { display: string } } = {
        '0100': { display: '0-5 years' },
        '0200': { display: '6-10 years' },
        '0300': { display: '11-15 years' },
        '0400': { display: '16-20 years' },
        '0500': { display: '21+ years' },
    };

    const DEFAULT_EXPERIENCE_BRACKETS_10YR: { [key: string]: { display: string } } = {
        '0100': { display: '0-2 years' },
        '0200': { display: '2-4 years' },
        '0300': { display: '4-7 years' },
        '0400': { display: '7-10 years' },
        '0500': { display: '10+ years' },
    };

    const DEFAULT_EXPERIENCE_SCORES: { [key: string]: number } = {
        '0100': 20,
        '0200': 40,
        '0300': 60,
        '0400': 80,
        '0500': 100,
    };

    const defaultSkill: AdminSkill = {
        id: '',
        display: '',
        parents: [],
        seekerHelpText: '',
        keywords: [],
        usesQualFramework: true,
        qualFramework: DEFAULT_COMPETENCY_BRACKETS_SELF_ASSESS,
        selectedQualFramework: 'SELF ASSESS',
        qualScores: DEFAULT_SKILL_SCORES,
        usesYrsExp: true,
        expFrameworkLength: '10',
        expFramework: DEFAULT_EXPERIENCE_BRACKETS_10YR,
        expScores: DEFAULT_EXPERIENCE_SCORES,
        enabled: true,
    };

    const dispatch = useDispatch();
    const adminSkills = useSelector(selectAdminSkillsData);
    // const [selectedRows, setSelectedRows] = useState<AdminSkill[]>([]);
    const [deleteSkill, setDeleteSkill] = useState<AdminSkill>();
    const [skill, setSkill] = useState<AdminSkill>(clone(defaultSkill));
    const [addEditModalOpen, setAddEditModalOpen] = React.useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const [search, setSearch] = useState('');
    const [rows, setRows] = useState<AdminSkill[]>([]);

    useEffect(() => {
        if (search === '') {
            setRows(adminSkills);
        } else {
            const newRows = adminSkills.filter(
                (item) => item.id.toLowerCase().includes(search.toLowerCase()) || item.display.toLowerCase().includes(search.toLowerCase())
            );
            setRows(newRows);
        }
    }, [adminSkills, search]);

    function handleSearchChange(event: any) {
        const { value } = event.target;
        setSearch(value);
    }

    useEffect(() => {
        dispatch(getAdminSkillsFramework());
    }, [dispatch]);

    const handleClickAdd = async () => {
        setSkill(clone(defaultSkill));
        setEditMode(false);
        setAddEditModalOpen(true);
    };

    const handleClose = () => {
        setAddEditModalOpen(false);
    };

    const handleConfirmClose = () => {
        setConfirmModalOpen(false);
    };

    function handleClickRemove(params: any) {
        setDeleteSkill(params.row);
        setConfirmModalOpen(true);
    }

    async function handleSkillDelete(uuid: string | undefined) {
        if (uuid) {
            dispatch(deleteAdminSkill(uuid));
            setConfirmModalOpen(false);
            dispatch(showSuccessSnackbar('Deleted successfully!'));
        } else {
            console.error("Skill uuid shouldn't be null");
            dispatch(showErrorSnackbar("Skill uuid shouldn't be null"));
        }
    }

    function handleClickEdit(params: any) {
        const newSkill = clone(params.row as AdminSkill);
        const expFw = clone({ ...newSkill.expFramework });
        const qualification = newSkill.selectedQualFramework || 'AQF';

        Object.keys(expFw).map((level, idx) => {
            return (expFw[level] = {
                sortIdx: idx,
                ...expFw[level],
            });
        });

        const eScores = clone({ ...newSkill.expScores });
        const mergedExpWithSortIdx = {
            ...newSkill,
            selectedQualFramework: qualification,
            parents: newSkill.parents || [],
            expFramework: { ...expFw },
            expScores: { ...eScores },
        };
        setSkill(mergedExpWithSortIdx);
        setEditMode(true);
        setTimeout(() => setAddEditModalOpen(true), 175);
    }

    async function handleSaveSkillset() {
        if (!skill.id) {
            dispatch(showErrorSnackbar("Skill 'ID' needs a value"));
        } else if (!skill.display) {
            dispatch(showErrorSnackbar("Skill 'Display' should have a value"));
        } else {
            dispatch(upsertSkill(skill));
            dispatch(getAdminSkillsFramework);
            setAddEditModalOpen(false);
        }
    }

    const handleChange = (event: any) => {
        const { name, value } = event.target;

        if (name === 'parentSkill') {
            setSkill({
                ...skill,
                parents: [...(skill.parents || []), value],
            });
        } else {
            setSkill({
                ...skill,
                [name]: value,
            });
        }
    };

    const handleExpFrameworkLengthChange = (event: any) => {
        const { name, value } = event.target;

        if (name === 'expFrameworkLength') {
            if (value === 10) {
                setSkill({
                    ...skill,
                    expFramework: DEFAULT_EXPERIENCE_BRACKETS_10YR,
                    expFrameworkLength: value,
                });
            } else if (value === 20) {
                setSkill({
                    ...skill,
                    expFramework: DEFAULT_EXPERIENCE_BRACKETS_20YR,
                    expFrameworkLength: value,
                });
            }
        }
    };

    const handleCompetenceyFrameworkChange = (event: any) => {
        const { value } = event.target;
        let qualFramework = {};

        switch (value) {
            case 'AQF':
                qualFramework = DEFAULT_SKILL_FRAMEWORK;
                break;
            case 'SELF ASSESS':
                qualFramework = DEFAULT_COMPETENCY_BRACKETS_SELF_ASSESS;
                break;
            case 'HYBRID':
                qualFramework = DEFAULT_COMPETENCY_BRACKETS_HYBRID;
                break;
            case 'LANGUAGE':
                qualFramework = DEFAULT_COMPETENCY_BRACKETS_LANGUAGE;
                break;
            case 'GEOGRAPHY':
                qualFramework = DEFAULT_COMPETENCY_BRACKETS_GEOGRAPHY;
                break;
            default:
                qualFramework = DEFAULT_SKILL_FRAMEWORK;
        }

        setSkill({
            ...skill,
            qualFramework: qualFramework,
            selectedQualFramework: value,
        });
    };

    const handleEnabled = (event: any) => {
        const { checked } = event.target;

        setSkill({
            ...skill,
            enabled: checked,
        });
    };

    const handleExpChecked = (event: any) => {
        const { checked } = event.target;

        const updatedSkill: AdminSkill = {
            ...skill,
            usesYrsExp: checked,
            expFramework: checked
                ? {
                      ...DEFAULT_EXPERIENCE_BRACKETS_20YR,
                  }
                : undefined,
            expScores: checked
                ? {
                      ...DEFAULT_EXPERIENCE_SCORES,
                  }
                : undefined,
        };

        setSkill(updatedSkill);
    };

    const handleQualChecked = (event: any) => {
        const { checked } = event.target;

        const updatedSkill: AdminSkill = {
            ...skill,
            usesQualFramework: checked,
            qualFramework: checked
                ? {
                      ...DEFAULT_SKILL_FRAMEWORK,
                  }
                : undefined,
            qualScores: checked
                ? {
                      ...DEFAULT_SKILL_SCORES,
                  }
                : undefined,
        };

        setSkill(updatedSkill);
    };

    const handleQualFrameworkChange = (event: any) => {
        const { name, value } = event.target;
        const mergedSkill = {
            ...skill,
            qualFramework: {
                ...skill.qualFramework,
                [name]: {
                    display: value,
                },
            },
        };
        setSkill(mergedSkill);
    };

    const handleQualScoresChange = (event: any) => {
        const { name, value } = event.target;
        const mergedSkill = {
            ...skill,
            qualScores: {
                ...skill.qualScores,
                [name]: Number(value),
            },
        };

        setSkill(mergedSkill);
    };

    const handleExpFrameworkValChange = (event: any) => {
        const { name, value } = event.target;
        const mergedSkill = {
            ...skill,
            expFramework: {
                ...skill.expFramework,
                [name]: {
                    ...(skill.expFramework || {})[name],
                    display: value,
                },
            },
        };
        setSkill(mergedSkill);
    };

    const handleExpFrameworkScoresChange = (event: any) => {
        const { name, value } = event.target;

        const mergedSkill = {
            ...skill,
            expScores: {
                ...skill.expScores,
                [name]: Number(value),
            },
        };

        setSkill(mergedSkill);
    };

    const handleKeywordDelete = (keywordToDelete: any) => () => {
        const keywords = skill.keywords;
        const newKeywords = keywords?.filter((keyword: string) => keyword !== keywordToDelete);
        setSkill({ ...skill, keywords: [...newKeywords] });
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            const chipValue = event.target.value.trim();
            if (chipValue !== '') {
                if (skill.keywords.includes(chipValue)) {
                    dispatch(showErrorSnackbar(`${chipValue} already exists!`));
                    return;
                }
                setSkill({ ...skill, keywords: [...(skill.keywords || ''), chipValue] });
                event.target.value = '';
            }
        }
    };

    function removeExpLevel(key: string) {
        const mergedSkill = { ...skill };
        if (skill.expFramework) {
            delete skill.expFramework[key];
        }
        if (skill.expScores) {
            delete skill.expScores[key];
        }

        setSkill(mergedSkill);
    }

    function addExpLevel(level: string) {
        let newLevel = '';

        if (level.substring(0, 5) === 'LEVEL') {
            //Old method
            newLevel = 'LEVEL' + (Number(level.replaceAll('LEVEL', '')) + 1);
        } else {
            //New method
            newLevel = parseInt(level) + 100 + '';
            if (parseInt(newLevel) < 1000) {
                newLevel = '0' + newLevel;
            }
        }

        const mergedSkill = {
            ...skill,
            expFramework: {
                ...skill.expFramework,
                [newLevel]: {
                    display: '',
                    // 'sortIdx': prevSortIdx + 1,
                },
            },
        };

        setSkill(mergedSkill);
    }

    function removeQualLevel(key: string) {
        const mergedSkill = { ...skill };

        if (skill.qualFramework) {
            delete skill.qualFramework[key];
        }
        if (skill.qualScores) {
            delete skill.qualScores[key];
        }

        setSkill(mergedSkill);
    }

    function addQualLevel(level: string) {
        let newLevel = '';

        if (level.substring(0, 5) === 'LEVEL') {
            //Old method
            newLevel = 'LEVEL' + (Number(level.replaceAll('LEVEL', '')) + 1);
        } else {
            //New method
            newLevel = parseInt(level) + 100 + '';
            if (parseInt(newLevel) < 1000) {
                newLevel = '0' + newLevel;
            }
        }

        const mergedSkill = {
            ...skill,
            qualFramework: {
                ...skill.qualFramework,
                [newLevel]: {
                    display: '',
                },
            },
        };

        setSkill(mergedSkill);
    }

    const handleDeleteParent = (parentSkill: string) => {
        setSkill({
            ...skill,
            parents: skill.parents?.filter((parent) => parent !== parentSkill),
        });
    };

    function disableSaveButton() {
        if (skill?.id && skill?.display) {
            return false;
        } else {
            return true;
        }
    }

    function getParents(item: Skill, source: Skill[]): any {
        if (item.parents && Array.isArray(item.parents) && item.parents.length > 0) {
            return item.parents
                .filter((p) => p !== item.id)
                .map((p) => {
                    const target = source.find((s) => s.id === p);
                    if (!target) {
                        return [[item.id]];
                    }
                    const parents = getParents(target, source);
                    return parents.map((newP: string[]) => [...newP, item.id]);
                })
                .flat();
        }
        return [[item.id]];
    }

    function getLevel(params: any) {
        const level = getParents(params.row, adminSkills)[0].length;

        return level;
    }

    function compare(a: any, b: any) {
        if (parseInt(a) < parseInt(b)) {
            return -1;
        }
        if (parseInt(a) > parseInt(b)) {
            return 1;
        }
        return 0;
    }

    const columns: GridColDef[] = [
        // { field: 'uuid', headerName: 'uuid', sortable: false, filterable: false, flex: 4 },
        { field: 'id', headerName: 'Id', sortable: true, filterable: true, flex: 1 },
        { field: 'display', headerName: 'Display', sortable: true, filterable: true, flex: 3 },
        {
            field: 'parents',
            headerName: 'Parents',
            sortable: true,
            filterable: true,
            flex: 3,
            renderCell: (params: GridRenderCellParams<AdminSkill>) => {
                const item = params.row.parents;
                if (!item) {
                    return;
                }
                return [...item].map((item: any, idx: any) => <Chip key={idx} style={{ marginLeft: '0.25rem' }} label={item} color={'default'} />);
            },
        },
        {
            field: 'enabled',
            headerName: 'Enabled',
            sortable: true,
            filterable: true,
            flex: 1,
            // params.row.enabled
            renderCell: (params: GridRenderCellParams<AdminSkill>) => {
                return (
                    <Chip
                        label={JSON.stringify(params.row.enabled).toUpperCase()}
                        color={params.row.enabled ? 'success' : 'error'}
                        variant="filled"
                        size="small"
                        icon={params.row.enabled ? <DoneSharpIcon /> : <CloseSharpIcon />}
                    />
                );
            },
        },
        {
            field: 'level',
            headerName: 'Level',
            sortable: true,
            filterable: true,
            flex: 1,
            valueGetter: getLevel,
        },
        { field: 'actions', headerName: 'Actions', sortable: false, flex: 1, renderCell: renderActions },
    ];

    function renderActions(params: GridRenderCellParams<any>) {
        return adminSkills.length >= 1 ? (
            <>
                <IconButton aria-label="Edit" onClick={() => handleClickEdit(params)}>
                    <EditIcon />
                </IconButton>

                <IconButton aria-label="Delete" onClick={() => handleClickRemove(params)}>
                    <DeleteIcon />
                </IconButton>
            </>
        ) : null;
    }

    return (
        <>
            <Grid container xs={12} spacing={4}>
                <Grid item xs={9}>
                    <Typography variant="h4" p={2}>
                        Skills Admin
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Button onClick={handleClickAdd} variant="contained" sx={{ marginTop: '10px', marginBottom: '10px' }}>
                        Add New <br />
                        Skill /experience
                    </Button>
                </Grid>
            </Grid>
            <Divider></Divider>
            <br />
            <Grid container xs={12}>
                <Grid item xs={6}>
                    <TextField label="Search" onChange={handleSearchChange} variant="standard" style={{ marginLeft: '1rem' }} />
                </Grid>
            </Grid>
            <Grid sx={{ flexGrow: 1 }} container spacing={2} padding={1}>
                <Grid item xs={12}>
                    <DataGrid
                        checkboxSelection={false}
                        disableSelectionOnClick
                        rows={rows}
                        columns={columns}
                        pageSize={15}
                        rowsPerPageOptions={[15]}
                        autoHeight
                        density="compact"
                        disableExtendRowFullWidth={false}
                        disableColumnSelector
                    />
                </Grid>
                <Grid item xs={11} />
            </Grid>
            <Dialog open={addEditModalOpen} fullWidth maxWidth={'md'}>
                {editMode ? <DialogTitle>Edit Skillset</DialogTitle> : <DialogTitle>Add Skillset</DialogTitle>}
                <DialogContent style={{ height: '700px' }}>
                    {skill && (
                        <>
                            <Grid sx={{ flexGrow: 1 }} container spacing={4} padding={2}>
                                {/* Row */}
                                <Grid item xs={6}>
                                    <TextField
                                        disabled={editMode ? true : false}
                                        label="Id"
                                        name="id"
                                        key="id"
                                        value={skill.id}
                                        onChange={handleChange}
                                        required
                                        fullWidth
                                    />
                                </Grid>

                                {/* Row */}
                                <Grid item xs={6}>
                                    <TextField label="Display" name="display" key="display" value={skill.display} onChange={handleChange} required fullWidth />
                                </Grid>

                                {/* Row */}
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={<Checkbox name="enabled" key="enabled" checked={skill.enabled} onChange={handleEnabled} />}
                                        label="Enabled"
                                    />
                                </Grid>
                                <Grid item xs={6} />

                                {/* Row */}
                                <Grid item xs={12} md={6} lg={6}>
                                    <Typography variant="h6" mb={'0.5rem'}>
                                        Parents:
                                    </Typography>
                                    <FormControl fullWidth>
                                        <Select name="parentSkill" input={<OutlinedInput placeholder="Add a parent" />} value="" onChange={handleChange}>
                                            {adminSkills
                                                .filter((skill) => getLevel({ row: skill }) < 4)
                                                .filter((s) => !skill.parents?.includes(s.id))
                                                .sort((a, b) => (a.display > b.display ? 1 : -1))
                                                .map((record, idx) => (
                                                    <MenuItem key={idx} value={record.id}>
                                                        {record.display} [{record.id}]
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                    <Stack direction="row" spacing={2} mt={'1rem'}>
                                        {skill.parents &&
                                            skill.parents.length > 0 &&
                                            skill.parents.map((result: string, idx) => (
                                                <Chip key={idx} label={result} onDelete={() => handleDeleteParent(result)} />
                                            ))}
                                    </Stack>
                                </Grid>

                                {skill?.parents?.length === 0 && (
                                    <Grid item xs={12} md={6} lg={6}>
                                        <Typography variant="h6" mb={'0.5rem'}>
                                            Level 1 Type:
                                        </Typography>
                                        <FormControl fullWidth>
                                            <Select name="type" value={skill.type || 'Other'} onChange={handleChange} style={{ minWidth: '250px' }}>
                                                <MenuItem value={'Other'}>Other</MenuItem>
                                                <MenuItem value={'Skills'}>Skills</MenuItem>
                                                <MenuItem value={'Experiences'}>Experiences</MenuItem>
                                                <MenuItem value={'Industry frameworks'}>Industry frameworks</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}

                                {/* Row */}
                                <Grid item xs={12}>
                                    <TextField
                                        label="Description"
                                        name="seekerHelpText"
                                        key="seekerHelpText"
                                        value={skill.seekerHelpText}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField label="Keywords" placeholder="Press 'enter' every after keyword" onKeyDown={handleKeyDown} fullWidth />
                                    <div style={{ marginTop: '1rem' }}>
                                        {skill?.keywords?.map((keyword: string) => (
                                            <Chip
                                                key={keyword}
                                                label={keyword}
                                                onDelete={handleKeywordDelete(keyword)}
                                                style={{ marginRight: '8px', marginBottom: '8px' }}
                                            />
                                        ))}
                                    </div>
                                </Grid>
                                <Grid item xs={2} />
                            </Grid>
                            <br />

                            <Divider></Divider>
                            <Grid sx={{ flexGrow: 1 }} container spacing={4} padding={2}>
                                {/* Row */}
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="usesQualFramework"
                                                key="usesQualFramework"
                                                checked={skill.usesQualFramework}
                                                onChange={handleQualChecked}
                                            />
                                        }
                                        label="Uses Competency Framework"
                                    />
                                </Grid>
                                <Grid item xs={6} />

                                {/* Row */}
                                {skill.usesQualFramework && (
                                    <React.Fragment>
                                        <Grid item xs={6}>
                                            <Typography variant="body2">Framework:</Typography>
                                            <Select
                                                name="expFrameworkLength"
                                                value={skill.selectedQualFramework}
                                                onChange={handleCompetenceyFrameworkChange}
                                                style={{ minWidth: '250px' }}
                                            >
                                                <MenuItem value="AQF">AQF</MenuItem>
                                                <MenuItem value="SELF ASSESS">Self Assess</MenuItem>
                                                <MenuItem value="HYBRID">Hybrid</MenuItem>
                                                <MenuItem value="LANGUAGE">Language</MenuItem>
                                                <MenuItem value="GEOGRAPHY">Geography</MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item xs={6} />

                                        <Grid item xs={12}>
                                            <Typography variant="h6">Competency Framework:</Typography>

                                            <Grid sx={{ flexGrow: 1 }} container spacing={1} padding={0} justifyContent="flex-start" alignItems="center">
                                                <>
                                                    <Grid item xs={2}>
                                                        <Typography variant="body1">Level</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="body1">Level Name</Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant="body1">Level Score</Typography>
                                                    </Grid>
                                                </>

                                                {skill.qualFramework &&
                                                    // skill.expScores &&
                                                    Object.keys(skill.qualFramework)
                                                        .sort(compare)
                                                        .map(function (key, idx) {
                                                            return (
                                                                <React.Fragment key={idx}>
                                                                    <Grid item xs={2}>
                                                                        <Typography variant="body1">{key}</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <TextField
                                                                            name={key}
                                                                            defaultValue={'Qualification level display name'}
                                                                            value={skill.qualFramework![key].display}
                                                                            onChange={handleQualFrameworkChange}
                                                                            required
                                                                            fullWidth
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <TextField
                                                                            name={key}
                                                                            value={skill.qualScores![key]}
                                                                            onChange={handleQualScoresChange}
                                                                            required
                                                                            fullWidth
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        {/* is this the last item? */}
                                                                        {Object.keys(skill.qualFramework!).length - 1 === idx ? (
                                                                            <>
                                                                                <IconButton
                                                                                    color="primary"
                                                                                    aria-label="removeQualLevel"
                                                                                    onClick={() => removeQualLevel(key)}
                                                                                >
                                                                                    <RemoveCircle />
                                                                                </IconButton>
                                                                                <IconButton
                                                                                    color="primary"
                                                                                    aria-label="addQualLevel"
                                                                                    // onClick={() => addExpLevel(skill.expFramework![key]?.sortIdx || 0)}
                                                                                    onClick={() => addQualLevel(key)}
                                                                                    disabled={skill.qualFramework![key].display?.length === 0 ? true : false}
                                                                                >
                                                                                    <AddCircle />
                                                                                </IconButton>
                                                                            </>
                                                                        ) : null}
                                                                    </Grid>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                )}

                                {/* Row */}
                                {/* {skill.usesQualFramework && (
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Qualification Scores:</Typography>
                                        <Typography variant="body1" >
                                            {JSON.stringify(skill.qualScores)}
                                        </Typography>
                                    </Grid>
                                )} */}
                            </Grid>
                            <br />

                            <Divider></Divider>
                            <Grid sx={{ flexGrow: 1 }} container spacing={4} padding={2}>
                                {/* Row */}
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={<Checkbox name="usesYrsExp" key="usesYrsExp" checked={skill.usesYrsExp} onChange={handleExpChecked} />}
                                        label="Uses Experience Framework"
                                    />
                                </Grid>
                                <Grid item xs={6} />

                                {skill.usesYrsExp && (
                                    <React.Fragment>
                                        {/* Row */}
                                        <Grid item xs={6}>
                                            <Typography variant="body2">Framework:</Typography>
                                            <Select
                                                name="expFrameworkLength"
                                                value={skill.expFrameworkLength}
                                                onChange={handleExpFrameworkLengthChange}
                                                style={{ minWidth: '250px' }}
                                            >
                                                <MenuItem value={10}>Ten years</MenuItem>
                                                <MenuItem value={20}>Twenty years</MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item xs={6} />

                                        {/* Row */}
                                        <Grid item xs={12}>
                                            <Typography variant="h6">Experience Framework:</Typography>

                                            <Grid sx={{ flexGrow: 1 }} container spacing={1} padding={0} justifyContent="flex-start" alignItems="center">
                                                <>
                                                    <Grid item xs={2}>
                                                        <Typography variant="body1">Level</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="body1">Experience Name</Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant="body1">Experience Score</Typography>
                                                    </Grid>
                                                </>

                                                {skill.expFramework &&
                                                    // skill.expScores &&
                                                    Object.keys(skill.expFramework)
                                                        .sort((a, b) => (skill.expFramework![a]?.sortIdx || 0) - (skill.expFramework![b]?.sortIdx || 0))
                                                        .map(function (key, idx) {
                                                            return (
                                                                <React.Fragment key={idx}>
                                                                    <Grid item xs={2}>
                                                                        <Typography variant="body1">{key}</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <TextField
                                                                            name={key}
                                                                            value={skill.expFramework![key].display}
                                                                            onChange={handleExpFrameworkValChange}
                                                                            required
                                                                            fullWidth
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <TextField
                                                                            name={key}
                                                                            value={skill.expScores![key]}
                                                                            onChange={handleExpFrameworkScoresChange}
                                                                            required
                                                                            fullWidth
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        {/* is this the last item? */}
                                                                        {Object.keys(skill.expFramework!).length - 1 === idx ? (
                                                                            <>
                                                                                <IconButton
                                                                                    color="primary"
                                                                                    aria-label="removeExpLevel"
                                                                                    onClick={() => removeExpLevel(key)}
                                                                                >
                                                                                    <RemoveCircle />
                                                                                </IconButton>
                                                                                <IconButton
                                                                                    color="primary"
                                                                                    aria-label="addExpLevel"
                                                                                    // onClick={() => addExpLevel(skill.expFramework![key]?.sortIdx || 0)}
                                                                                    onClick={() => addExpLevel(key)}
                                                                                    disabled={skill.expFramework![key].display?.length === 0 ? true : false}
                                                                                >
                                                                                    <AddCircle />
                                                                                </IconButton>
                                                                            </>
                                                                        ) : null}
                                                                    </Grid>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                )}

                                {/* Row */}
                                {/* {skill.usesYrsExp && (
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Experience Scores:</Typography>
                                        <Typography variant="body1" >
                                            {JSON.stringify(skill.expScores)}
                                        </Typography>
                                    </Grid>
                                )} */}
                            </Grid>
                            <br />
                        </>
                    )}
                    <br />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveSkillset} disabled={disableSaveButton()} variant="contained">
                        {editMode ? 'Save Skillset' : 'Create Skillset'}
                    </Button>
                </DialogActions>
            </Dialog>{' '}
            {/*Edit Skillset modal*/}
            <Dialog open={confirmModalOpen} onClose={handleConfirmClose} fullWidth maxWidth="sm">
                <DialogTitle>Confirm delete</DialogTitle>
                <Box position="absolute" top={2} right={2}>
                    <IconButton onClick={handleConfirmClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <p>
                        Are you sure you want to delete the <b>{deleteSkill?.display}</b> skillset?
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleConfirmClose}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={() => handleSkillDelete(deleteSkill?.uuid)}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>{' '}
            {/*Confirm delete modal*/}
        </>
    );
};

export default AdminSkills;
