import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { showSuccessSnackbar, showErrorSnackbar } from '../../../redux/actions/snackbarAction';
import { getInstance } from '../../../services/fetch';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { JobComment } from '../../../types/job-comments';
import { useParams, useSearchParams } from 'react-router-dom';
import { Grid, Typography, RadioGroup, FormControlLabel, Radio, TextField, Button, CircularProgress } from '@mui/material';
import { selectProfileData } from '../../../redux/selectors/profileSelector';
import { GenericModal } from '../../../components/modal';
import { feedbackMessage } from '../../../data/pingMessage';
import { ProfileOverview } from '../../../types/CandidateProfile';
import { assessmentStatus } from '../../../data/dropDownData';
import { jobDataKEY } from '../../../constant';
import { useLocalStorage } from '../../../hooks/useLocalStore';
import { JObFormData } from '../my-job/JobView';

const addSuffix = (word: string | undefined) => {
    if (!word) {
        return;
    }
    if (word.endsWith('s')) {
        return word + "'";
    } else {
        return word + "'s";
    }
};

function Assessment() {
    const dispatch = useDispatch();
    const profileData = useSelector(selectProfileData);
    const queryClient = useQueryClient();
    const { candidateId: userId } = useParams();
    const [JobComment, setJobComment] = useState<JobComment>({} as JobComment);
    const [currentComment, setCurrentComment] = useState('');
    const [messageModal, setMessageModal] = useState<boolean>(false);
    const [emailMessage, setEmailMessage] = useState<string>('');
    const [searchParams] = useSearchParams();
    const showJobAssessment = searchParams.get('showJobAssessment')?.toLowerCase() === 'true' ? true : false;
    const [additionalInformation, setAdditionalInformation] = useState<string | null>(null);
    // only gonna access the value
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [jobDetail, setJobDetail] = useLocalStorage<JObFormData>(jobDataKEY, {} as JObFormData);

    const profileCommentsQuery = useQuery(
        ['profile-comments', userId],
        async () => {
            const response = await getInstance().get<{ data: any; error: string }>(`/employer/profile-comments?profileId=${userId}`);
            return response.data;
        },
        {
            enabled: !!userId,
            cacheTime: 1000,
            staleTime: 1000,
        }
    );

    useEffect(() => {
        const fetchData = async () => {
            const response = await getInstance().get<{ data: any; error: string }>(
                `/employer/job-comments?profileId=${userId}&jobId=${jobDetail.settings?.id},${jobDetail.settings?.uuid}`
            );
            setAdditionalInformation(response.data.additionalInformation || null);
            setJobComment({ ...response.data.jobComments, profileId: userId, jobId: jobDetail.settings?.uuid });
        };
        fetchData();
    }, [jobDetail, userId]);

    const profileOverviewQuery = useQuery<ProfileOverview>(
        ['profile-overview', userId],
        async () => {
            const response = await getInstance().get<{ data: any; error: string }>(`/employer/search/profile?id=${userId}`);

            return {
                ...response.data,
                userId,
            };
        },
        {
            enabled: !!userId,
            cacheTime: 1000,
            staleTime: 1000,
        }
    );

    useEffect(() => {
        const name = `${profileOverviewQuery.data?.profile.firstname} ${profileOverviewQuery.data?.profile.surname}`;
        setEmailMessage(
            feedbackMessage({
                employer: { name: `${profileData.firstname} ${profileData.surname}` },
                candidate: { name },
                job: { id: jobDetail.settings?.id || '', name: jobDetail.description?.title || '', city: jobDetail.description?.city || '' },
            })
        );
    }, [
        JobComment.feedback,
        jobDetail.description?.city,
        jobDetail.description?.title,
        jobDetail.settings?.id,
        profileData.firstname,
        profileData.surname,
        profileOverviewQuery.data?.profile.firstname,
        profileOverviewQuery.data?.profile.surname,
    ]);

    const profileCommentsMutation = useMutation(async () => {
        if (!userId) {
            return;
        }

        try {
            await getInstance().post<{ error: string }, any>(`/employer/profile-comments`, {
                profileId: userId,
                comment: currentComment,
            });

            dispatch(showSuccessSnackbar('Comment updated successfully!'));
        } catch (error) {
            dispatch(showErrorSnackbar((error as Error).message || 'Something went wrong'));
        }
    });

    function handleJobCommentChange(event: any) {
        const {
            target: { name, value },
        } = event;
        setJobComment({ ...JobComment, [name]: value });
    }

    function handleJobRatingChange(event: any) {
        const {
            target: { value },
        } = event;
        setJobComment({ ...JobComment, ['rating']: parseInt(value) });
    }

    async function handleJobCommentSubmit() {
        try {
            await getInstance().post<{ error: string }, any>(`/employer/job-comments`, JobComment);
            dispatch(showSuccessSnackbar('Job comment updated Successfully'));
            queryClient.invalidateQueries({ queryKey: ['Job-comments', userId], exact: true });
        } catch (error) {
            dispatch(showErrorSnackbar((error as Error).message || 'Something went wrong'));
        }
    }

    // function handleSendCandidateFeedback() {
    //     setMessageModal(true);
    // }

    function handleMailMessageChange(event: any) {
        const {
            target: { value },
        } = event;
        setEmailMessage(value);
    }

    useEffect(() => {
        setCurrentComment(profileCommentsQuery.data?.comment || '');
    }, [profileCommentsQuery.data?.comment]);

    async function handleMailMessageSend() {
        const body = {
            id: userId,
            message: emailMessage,
        };
        getInstance()
            .post<{ error: string }, any>(`/employer/ping/feedback`, body)
            .then(() => {
                dispatch(showSuccessSnackbar(`Feedback sent successfully!`));
            })
            .catch(() => dispatch(showErrorSnackbar('Error sending feedback,pLease try again latter')));
    }
    return (
        <>
            <Grid sx={{ flexGrow: 1 }} spacing={2} padding={1} container direction="row" justifyContent="flex-start" alignItems="flex-end">
                {profileData?.organisationName && (
                    <>
                        {profileCommentsQuery.isLoading ? (
                            <Grid item xs={12} style={{ paddingLeft: '2rem', minHeight: 110 }}>
                                <CircularProgress />
                            </Grid>
                        ) : (
                            <>
                                <Grid container>
                                    <Typography variant="h6" color={'primary'}>
                                        {addSuffix(profileData?.organisationName)} General Comments:
                                    </Typography>
                                </Grid>
                                <Grid item xs={11}>
                                    <TextField
                                        id="overviewComments"
                                        name="overviewComments"
                                        placeholder="Use this area to record notes on your engagement with this candidate"
                                        multiline
                                        rows={2}
                                        fullWidth
                                        value={currentComment}
                                        onChange={(e) => {
                                            setCurrentComment(e.currentTarget.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            profileCommentsMutation.mutate();
                                        }}
                                        disabled={profileCommentsMutation.isLoading}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </>
                )}
            </Grid>
            {additionalInformation && (
                <Grid container p={1}>
                    <Typography variant="h6" color={'primary'}>
                        Candidate Additional Information
                    </Typography>
                    <Grid item xs={12}>
                        {additionalInformation}
                    </Grid>
                </Grid>
            )}
            {showJobAssessment && (
                <>
                    <Grid container p={1}>
                        <Typography variant="h6" color={'primary'}>
                            Candidate Assessment for Job {jobDetail.settings?.id}
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Grid item xs={3} padding={'1rem'}>
                            <RadioGroup row={false} value={JobComment?.rating || ''} onChange={handleJobRatingChange}>
                                {assessmentStatus.map((item) => (
                                    <FormControlLabel key={item.key} value={item.value} control={<Radio />} label={item.label} />
                                ))}
                            </RadioGroup>
                        </Grid>
                        <Grid xs={9}>
                            <Grid xs={12} display={'flex'} justifyContent={'space-between'} mt={'3rem'}>
                                <Grid xs={10}>
                                    <TextField
                                        label={`Suitability Comment (visible only to users within ${profileData?.organisationName})`}
                                        id="overviewComments"
                                        name="comment"
                                        placeholder={`Use this area to record internal comments on this candidate’s suitability for this job (viewable by all users with employer access within ${profileData?.organisationName})
                                        `}
                                        multiline
                                        rows={6}
                                        fullWidth
                                        value={JobComment?.comment || ''}
                                        onChange={handleJobCommentChange}
                                    />
                                </Grid>
                                <Grid xs={1}>
                                    <Button variant="outlined" onClick={handleJobCommentSubmit}>
                                        Save Candidate Assessment
                                    </Button>
                                </Grid>
                            </Grid>

                            {/* <Grid xs={12} display={'flex'} justifyContent={'space-between'} mt={'2rem'}>
                                <Grid xs={10}>
                                    <TextField
                                        id="job-feedback"
                                        name="feedback"
                                        placeholder="Use this area to record notes on your engagement with this candidate"
                                        multiline
                                        label="Candidate Feedback (visible to candidates when sent by email)"
                                        rows={6}
                                        fullWidth
                                        value={emailMessage || ''}
                                        onChange={handleJobCommentChange}
                                    />
                                </Grid>
                                <Grid xs={1}>
                                    <Button variant="outlined" onClick={handleSendCandidateFeedback}>
                                        Send Candidate Feedback
                                    </Button>
                                </Grid>
                            </Grid> */}
                        </Grid>
                    </Grid>
                </>
            )}
            {messageModal && (
                <GenericModal
                    title="Send feedback to Candidate"
                    successLabel="Send message"
                    handleSuccess={handleMailMessageSend}
                    handleClose={() => {
                        setEmailMessage('');
                        setMessageModal(false);
                    }}
                >
                    <Grid>
                        <TextField
                            multiline
                            fullWidth
                            value={emailMessage}
                            label="Message body (modify as necessary)"
                            name="message"
                            rows={12}
                            onChange={handleMailMessageChange}
                        />
                    </Grid>
                </GenericModal>
            )}
        </>
    );
}

export default Assessment;
