import { ListItemText, MenuItem } from '@mui/material';
import styled from 'styled-components';

export const CustomListItemText = styled(ListItemText)`
    & .MuiListItemText-primary {
        font-size: 0.875rem !important;
    }
`;

export const CustomMenuItem = styled(MenuItem)`
    white-space: normal;
    height: 3rem;
`;
