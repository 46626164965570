export type PermissionObject = {
    isAdmin: boolean;
    isOwner: boolean;
    isAuthor?: boolean;
};

export const settingPermission = (permission: PermissionObject): boolean => {
    const { isAdmin, isOwner } = permission;

    if (isAdmin) {
        return false;
    }
    if (!isOwner) {
        return true;
    }
    return false;
};

export const descriptionPermission = (permission: PermissionObject): boolean => {
    const { isAdmin, isOwner, isAuthor } = permission;

    if (isAdmin) {
        return false;
    }
    if (!isOwner || !isAuthor) {
        return true;
    }
    return false;
};

export const tabPermission = (permission: PermissionObject): boolean => {
    const { isAdmin, isOwner, isAuthor } = permission;
    if (isAdmin) {
        return true;
    }
    if (isOwner || isAuthor) {
        return true;
    }
    return false;
};
