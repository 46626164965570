import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clone } from 'ramda';
import {
    Button,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    Link as StyledLink,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
    CircularProgress,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { DataGrid, GridFilterModel, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import SubscribeBanner from '../../../components/SubscribeBanner';
import { ProfileResult } from '../../../types/search';
import { Shortlist as ShortlistType, ShortlistResponse } from '../../../types/shortlist';
import {
    getShortlists,
    getSharedShortlists,
    deleteShortlist,
    describeShortlist,
    describeSharedShortlist,
    upsertShortlist,
    shareShortlist,
} from '../../../redux/actions/shortlistAction';
import {
    selectShortlistData,
    selectSharedShortlistData,
    selectShortlist,
    selectSharedShortlist,
    selectCurrentShortlist,
} from '../../../redux/selectors/shortlistSelector';
import { selectIsUserEmployer } from '../../../redux/selectors/userSelector';
import { mapValueToLabel } from '../../../utils/mapValueToLabel';
import { securityClearanceDropdown } from '../../../data/dropDownData';
import { Link, useLocation } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';

const DEFAULT_FILTER_MODEL: GridFilterModel = { items: [] };
const DEFAULT_SORT_MODEL: GridSortModel = [];

const QueryKeys = {
    FILTER_MODEL_1: ['short-list-filter-model-1'],
    SORT_MODEL_1: ['short-list-sort-model-1'],
    FILTER_MODEL_2: ['short-list-filter-model-2'],
    SORT_MODEL_2: ['short-list-sort-model-2'],
    SELECTED_OWN_SHORTLIST: ['selected-own-shortlist'],
    SELECTED_SHARED_SHORTLIST: ['selected-shared-shortlist'],
};

const Shortlist = () => {
    const location = useLocation();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const isUserEmployer = useSelector(selectIsUserEmployer);
    const targetShortlistMetadata = useSelector(selectCurrentShortlist);

    const selectedShortlistQuery = useQuery(
        QueryKeys.SELECTED_OWN_SHORTLIST,
        () => {
            return '';
        },
        {
            enabled: true,
            cacheTime: Infinity,
            staleTime: Infinity,
        }
    );

    const selectedShortlist = selectedShortlistQuery.data || '';

    const setSelectedShortlist = useCallback(
        (newValue: string) => {
            queryClient.setQueryData(QueryKeys.SELECTED_OWN_SHORTLIST, newValue);
            queryClient.setQueryData(QueryKeys.FILTER_MODEL_1, DEFAULT_FILTER_MODEL);
            queryClient.setQueryData(QueryKeys.SORT_MODEL_1, DEFAULT_SORT_MODEL);
        },
        [queryClient]
    );

    const selectedSharedShortListQuery = useQuery(
        QueryKeys.SELECTED_SHARED_SHORTLIST,
        () => {
            return '';
        },
        {
            enabled: true,
            cacheTime: Infinity,
            staleTime: Infinity,
        }
    );

    const selectedSharedShortlist = selectedSharedShortListQuery.data || '';

    const setSelectedSharedShortlist = useCallback(
        (newValue: string) => {
            queryClient.setQueryData(QueryKeys.SELECTED_SHARED_SHORTLIST, newValue);
            queryClient.setQueryData(QueryKeys.FILTER_MODEL_2, DEFAULT_FILTER_MODEL);
            queryClient.setQueryData(QueryKeys.SORT_MODEL_2, DEFAULT_SORT_MODEL);
        },
        [queryClient]
    );

    const handleDeleteShortlist = () => {
        dispatch(deleteShortlist(selectedShortlist));
    };

    const handleShareShortlist = () => {
        const clonedShortlist = clone(targetShortlistMetadata) || ({} as ShortlistType);

        dispatch(shareShortlist(clonedShortlist));
    };

    const filterModelQuery1 = useQuery<GridFilterModel>(
        QueryKeys.FILTER_MODEL_1,
        () => {
            return DEFAULT_FILTER_MODEL;
        },
        {
            enabled: true,
            cacheTime: Infinity,
            staleTime: Infinity,
        }
    );

    const sortModelQuery1 = useQuery<GridSortModel>(
        QueryKeys.SORT_MODEL_1,
        () => {
            return DEFAULT_SORT_MODEL;
        },
        {
            enabled: true,
            cacheTime: Infinity,
            staleTime: Infinity,
        }
    );

    const filterModelQuery2 = useQuery<GridFilterModel>(
        QueryKeys.FILTER_MODEL_2,
        () => {
            return DEFAULT_FILTER_MODEL;
        },
        {
            enabled: true,
            cacheTime: Infinity,
            staleTime: Infinity,
        }
    );

    const sortModelQuery2 = useQuery<GridSortModel>(
        QueryKeys.SORT_MODEL_2,
        () => {
            return DEFAULT_SORT_MODEL;
        },
        {
            enabled: true,
            cacheTime: Infinity,
            staleTime: Infinity,
        }
    );

    const handleShortlistChange = (event: SelectChangeEvent) => {
        setSelectedShortlist(event.target.value);

        if (event.target.value !== 'N/A') {
            dispatch(describeShortlist(event.target.value));
        }
    };

    const shortlists = useSelector(selectShortlistData);
    const sharedShortlists = useSelector(selectSharedShortlistData);
    const _shortlist = useSelector(selectShortlist);
    const _sharedShortlist = useSelector(selectSharedShortlist);

    const [_isLoading, setIsLoading] = useState(true);
    const isLoading = _isLoading || filterModelQuery1.isLoading || sortModelQuery1.isLoading;

    useEffect(() => {
        if (isLoading) {
            dispatch(selectedShortlist ? describeShortlist(selectedShortlist) : getShortlists());
            dispatch(selectedSharedShortlist ? describeSharedShortlist(selectedSharedShortlist) : getSharedShortlists());

            setIsLoading(false);
        }
    }, [dispatch, isLoading, queryClient, selectedSharedShortlist, selectedShortlist]);

    const handleSharedShortlistChange = (event: SelectChangeEvent) => {
        setSelectedSharedShortlist(event.target.value);

        if (event.target.value !== 'N/A') {
            dispatch(describeSharedShortlist(event.target.value));
        }
    };

    useEffect(() => {
        if (!isLoading && shortlists?.[0]?.uuid && !selectedShortlist) {
            setSelectedShortlist(shortlists[0].uuid);
            dispatch(describeShortlist(shortlists[0].uuid));
        }
    }, [dispatch, isLoading, selectedShortlist, setSelectedShortlist, shortlists]);

    useEffect(() => {
        if (!isLoading && sharedShortlists?.[0]?.uuid && !selectedSharedShortlist) {
            setSelectedSharedShortlist(sharedShortlists[0].uuid);
            dispatch(describeSharedShortlist(sharedShortlists[0].uuid));
        }
    }, [dispatch, isLoading, selectedSharedShortlist, setSelectedSharedShortlist, sharedShortlists]);

    function handleClickRemove(params: any) {
        const clonedShortlist = clone(targetShortlistMetadata) || ({} as ShortlistType);

        if (clonedShortlist.items && clonedShortlist.uuid) {
            clonedShortlist.items = (clonedShortlist.items as string[]).filter((item) => item !== params.row.userId);
            dispatch(upsertShortlist(clonedShortlist));
        }
    }

    function disableDeleteShortlistButton() {
        if (selectedShortlist) {
            return false;
        } else {
            return true;
        }
    }

    // owned shortlist DataGrid
    function renderCandidateIdLinkOwned(params: GridRenderCellParams<ProfileResult>) {
        return (_shortlist.items || []).length >= 1 ? (
            <Link
                to={`/overview/${params.row.userId}`}
                state={{
                    from: location.pathname,
                }}
            >
                <StyledLink component="span">{params.row.candidateId}</StyledLink>
            </Link>
        ) : null;
    }

    function renderActions(params: GridRenderCellParams<any>) {
        return (_shortlist.items || []).length >= 1 ? (
            <>
                <IconButton aria-label="Delete" onClick={() => handleClickRemove(params)}>
                    <DeleteIcon />
                </IconButton>
            </>
        ) : null;
    }

    if (isLoading) {
        return <CircularProgress />;
    }

    const gridColumnsOwned = [
        { field: 'candidateId', headerName: 'Candidate ID', flex: 1.5, renderCell: renderCandidateIdLinkOwned },
        { field: 'firstname', headerName: 'Firstname', flex: 2 },
        {
            field: 'surname',
            headerName: 'Surname',
            flex: 2,
            renderCell: (params: GridRenderCellParams<any>) => {
                return params.row.surname ? params.row.surname.toUpperCase() : '';
            },
        },
        {
            field: 'securityClearance',
            headerName: 'Security Clearance',
            flex: 3,
            renderCell: (params: GridRenderCellParams<any>) => {
                return Array.isArray(params.row.securityClearance) ? (
                    <div>
                        {(params.row.securityClearance || []).map((option: string) => {
                            return <div key={option}>{mapValueToLabel(securityClearanceDropdown, option)}</div>;
                        })}{' '}
                    </div>
                ) : (
                    mapValueToLabel(securityClearanceDropdown, params.row.securityClearance || '')
                );
            },
        },
        { field: 'auCitizenshipStatus', headerName: 'Australian Citizenship Status', flex: 4 },
        { field: 'comments', headerName: 'Comments', flex: 4 },
        { field: 'actions', headerName: 'Actions', sortable: false, flex: 1, renderCell: renderActions },
    ];

    const gridRowsOwned = ((_shortlist.items || []) as ShortlistResponse[]).map((obj) => ({
        ...obj,
        id: obj.userId,
    }));

    // shared shortlist DataGrid
    function renderCandidateIdLinkShared(params: GridRenderCellParams<ProfileResult>) {
        return (_sharedShortlist.items || []).length >= 1 ? (
            <Link
                to={`/overview/${params.row.userId}`}
                state={{
                    from: location.pathname,
                }}
            >
                <StyledLink component="span">{params.row.candidateId}</StyledLink>
            </Link>
        ) : null;
    }

    const gridColumnsShared = [
        { field: 'candidateId', headerName: 'Candidate ID', flex: 1.5, renderCell: renderCandidateIdLinkShared },
        { field: 'firstname', headerName: 'Firstname', flex: 2 },
        {
            field: 'surname',
            headerName: 'Surname',
            flex: 2,
            renderCell: (params: GridRenderCellParams<any>) => {
                return params.row.surname ? params.row.surname.toUpperCase() : '';
            },
        },
        {
            field: 'securityClearance',
            headerName: 'Security Clearance',
            flex: 3,
            renderCell: (params: GridRenderCellParams<any>) => {
                return Array.isArray(params.row.securityClearance) ? (
                    <div>
                        {(params.row.securityClearance || []).map((option: string) => {
                            return <div key={option}>{mapValueToLabel(securityClearanceDropdown, option)}</div>;
                        })}{' '}
                    </div>
                ) : (
                    mapValueToLabel(securityClearanceDropdown, params.row.securityClearance || '')
                );
            },
        },
        { field: 'auCitizenshipStatus', headerName: 'Australian Citizenship Status', flex: 4 },
        { field: 'comments', headerName: 'Comments', flex: 4 },
    ];

    const gridRowsShared = ((_sharedShortlist.items || []) as ShortlistResponse[]).map((obj) => ({
        ...obj,
        id: obj.userId,
    }));

    return (
        <>
            {!isUserEmployer && <SubscribeBanner />}
            <Typography variant="h4" p={2}>
                My Shortlists
            </Typography>

            <Divider></Divider>
            <br />

            <Grid sx={{ flexGrow: 1 }} container alignItems="center" spacing={2} padding={1}>
                <Grid item xs={12}>
                    <FormLabel component="legend">My own shortlists:</FormLabel>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth component="fieldset">
                        <Select onChange={handleShortlistChange} name="ownedShortlists" value={selectedShortlist}>
                            {shortlists?.map((record, idx) => (
                                <MenuItem key={idx} value={record.uuid}>
                                    {record.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={8}>
                    <Button onClick={handleDeleteShortlist} disabled={disableDeleteShortlistButton()} variant="contained" sx={{ mr: 1 }}>
                        Delete shortlist
                    </Button>
                    <Button onClick={handleShareShortlist} disabled={disableDeleteShortlistButton()} variant="contained">
                        Share shortlist
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <DataGrid
                        filterModel={filterModelQuery1.data || DEFAULT_FILTER_MODEL}
                        onFilterModelChange={(model) => {
                            queryClient.setQueryData(QueryKeys.FILTER_MODEL_1, model);
                        }}
                        sortModel={sortModelQuery1.data || DEFAULT_SORT_MODEL}
                        onSortModelChange={(model) => {
                            queryClient.setQueryData(QueryKeys.SORT_MODEL_1, model);
                        }}
                        columns={gridColumnsOwned}
                        rows={gridRowsOwned}
                        checkboxSelection={false}
                        disableSelectionOnClick
                        autoHeight
                        density="compact"
                        pageSize={15}
                        rowsPerPageOptions={[15]}
                        getRowHeight={() => 'auto'}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel component="legend">{`My company's shortlists:`}</FormLabel>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth component="fieldset">
                        <Select onChange={handleSharedShortlistChange} name="sharedShortlists" value={selectedSharedShortlist}>
                            {sharedShortlists.map((record, idx) => (
                                <MenuItem key={idx} value={record.uuid}>
                                    {record.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs />
                <Grid item xs={12}>
                    <DataGrid
                        filterModel={filterModelQuery2.data || DEFAULT_FILTER_MODEL}
                        onFilterModelChange={(model) => {
                            queryClient.setQueryData(QueryKeys.FILTER_MODEL_2, model);
                        }}
                        sortModel={sortModelQuery2.data || DEFAULT_SORT_MODEL}
                        onSortModelChange={(model) => {
                            queryClient.setQueryData(QueryKeys.SORT_MODEL_2, model);
                        }}
                        columns={gridColumnsShared}
                        rows={gridRowsShared}
                        checkboxSelection={false}
                        disableSelectionOnClick
                        autoHeight
                        density="compact"
                        pageSize={15}
                        rowsPerPageOptions={[15]}
                        getRowHeight={() => 'auto'}
                        sx={{
                            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default Shortlist;
