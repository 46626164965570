export const PROFILE_STEPS = [
    {
        label: 'Account Details',
        page: '/account',
    },
    {
        label: 'Account Type',
        page: '/accountType',
    },
    {
        label: 'Jobseeker Details',
        page: '/profile',
    },
    {
        label: 'Skills & Experience',
        page: '/skills',
    },
    {
        label: 'Attachments',
        page: '/files',
    },
];
