import { Link, useParams } from 'react-router-dom';
import { getInstance } from '../../../../services/fetch';
import { useQuery } from '@tanstack/react-query';
import Loader from '../../../../components/loader';
import { Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { selectIsUserAdmin } from '../../../../redux/selectors/userSelector';
import { useSelector } from 'react-redux';
import { Profile } from '../../../../types/CandidateProfile';

const Applicants = () => {
    const { jobId } = useParams<{ jobId: string }>();
    const isAdmin = useSelector(selectIsUserAdmin);
    const applicantsQuery = useQuery(
        ['applicants', jobId],
        async () => {
            if (!isAdmin) {
                const response = await getInstance().get<{ error: string; data: Partial<Profile>[] }>(`/employer/job/${jobId}/applicants`);
                if (response.error) {
                    throw new Error(response.error);
                }
                const result = response.data.map((item) => {
                    return {
                        ...item,
                        city: item.location?.city,
                        state: item.location?.state,
                        country: item.location?.country,
                        postcode: item.location?.postcode,
                    };
                });
                return result;
            }
            const response = await getInstance().get<{ error: string; data: Partial<Profile>[] }>(`/admin/job/${jobId}/applicants`);
            if (response.error) {
                throw new Error(response.error);
            }
            const result = response.data.map((item) => {
                return {
                    ...item,
                    city: item.location?.city,
                    state: item.location?.state,
                    country: item.location?.country,
                    postcode: item.location?.postcode,
                };
            });
            return result;
        },
        {
            enabled: !!jobId,
            cacheTime: 1000,
            staleTime: 1000,
            refetchInterval: 60000, // Refetch every 60 seconds (60000 milliseconds)
        }
    );

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 1,
            renderCell: (params: any) => (
                <Link
                    to={`/overview/${params.row.id}?showJobAssessment=true`}
                    state={{
                        from: location.pathname,
                    }}
                >
                    {params.row.candidateId}
                </Link>
            ),
        },
        {
            field: 'firstname',
            headerName: 'First Name',
            flex: 1,
        },
        {
            field: 'surname',
            headerName: 'Last Name',
            flex: 1,
        },
        {
            field: 'city',
            headerName: 'City',
            flex: 1,
        },
        {
            field: 'country',
            headerName: 'Country',
            flex: 1,
        },
        {
            field: 'auCitizenshipStatus',
            headerName: 'Citizenship Status',
            flex: 1,
        },
        {
            field: 'securityClearance',
            headerName: 'Min. Sec. Clearance',
            flex: 1,
        },
    ];
    if (applicantsQuery.isLoading) return <Loader />;
    return (
        <Grid item xs={12} mt={'2rem'}>
            <DataGrid
                style={{
                    minHeight: 500,
                }}
                rowsPerPageOptions={[10, 25, 50, 100]}
                autoHeight
                rows={applicantsQuery.data || []}
                columns={columns}
                rowHeight={38}
                disableSelectionOnClick
            />
        </Grid>
    );
};
export default Applicants;
