import { useQuery } from '@tanstack/react-query';
import { getInstance } from '../services/fetch';
import { AdminSkill } from '../types/skill';

export const useAdminSkillsQueryInfo = () => {
    return useQuery(
        ['all-skills'],
        async () => {
            const response = await getInstance().get<{ data: AdminSkill[]; error: string }>(`/admin/skills`);

            return response.data;
        },
        {
            refetchOnMount: true,
            cacheTime: 3000,
            staleTime: 3000,
            enabled: true,
        }
    );
};
