import { Typography, Link as StyledLink, Button, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Chip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getInstance } from '../../../../services/fetch';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../../redux/actions/snackbarAction';
import { createNewJob } from '../../../../redux/actions/jobActions';
import { jobStatus } from '../../../../data/dropDownData';
import { selectIsUserAdmin } from '../../../../redux/selectors/userSelector';
import { Job } from '../../../../types/job';
import { selectOrganisationsData } from '../../../../redux/selectors/organisationsSelector';
import { getOrganisations } from '../../../../redux/actions/organisationsAction';
type ActionType = 'warn' | 'archive';
type selected = {
    type: ActionType;
    job?: Job;
};

const JobList: React.FC = () => {
    const [conformationModal, setConformationModal] = useState<boolean>(false);
    const [selected, setSelected] = useState<selected>({} as selected);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const isAdminUser = useSelector(selectIsUserAdmin);
    const organizations = useSelector(selectOrganisationsData);
    useEffect(() => {
        dispatch(getOrganisations());
    }, [dispatch]);
    const handleCreateJob = async () => {
        const { uuid } = (await dispatch(createNewJob())) as unknown as Job;
        if (uuid) {
            navigate(`/my-jobs/${uuid}`);
            return;
        }
        dispatch(showErrorSnackbar('Failed to create new job, Try again latter'));
    };
    const JobList = useQuery(
        ['jobList'],
        async () => {
            const response = await getInstance().get<{ data: any; error: string }>(`/admin/job`);
            if (response.error) {
                throw new Error(response.error);
            }
            return response.data;
        },
        {
            cacheTime: 1000,
            staleTime: 1000,
        }
    );

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 1,
            renderCell: (params: any) => (
                <Link
                    to={`/my-jobs/${params.row.uuid}`}
                    state={{
                        from: location.pathname,
                    }}
                >
                    <StyledLink component="span">{params.row.id}</StyledLink>
                </Link>
            ),
        },
        {
            field: 'title',
            headerName: 'Title',
            flex: 1,
        },
        {
            field: 'organization',
            headerName: 'Organization',
            renderCell: (params: any) => <Typography>{(organizations || [])?.find((item) => item.id === params.row.organization)?.name}</Typography>,
            flex: 1,
        },
        {
            field: 'owner',
            headerName: 'Author',
            renderCell: (params: any) => (
                <Typography>
                    {params.row.authorInformation?.firstname} {params.row.authorInformation?.surname}
                </Typography>
            ),
            flex: 1,
        },
        {
            field: 'city',
            headerName: 'City',
            flex: 1,
        },
        {
            field: 'country',
            headerName: 'Country',
            flex: 1,
        },
        {
            field: 'securityClearance',
            headerName: 'Min. Sec. Clearance',
            flex: 1,
        },
        {
            field: 'referenceNumber',
            headerName: 'Other Ref No.',
            flex: 1,
        },
        {
            field: 'status',
            headerName: 'Sharing Status',
            flex: 1,
            renderCell: (params: any) => {
                const status = jobStatus.find((item) => item.value == params.row.status);
                return <Chip label={status?.label} color={'default'} />;
            },
        },
        {
            field: 'Actions',
            renderCell: (params: any) => {
                return (
                    <>
                        {isAdminUser && (
                            <i className="fa-solid fa-trash mr-1" onClick={() => showDeleteModal(params.row, 'warn')} style={{ cursor: 'pointer' }}></i>
                        )}
                        {params.row.status !== (jobStatus.find((item) => item.label === 'Closed')?.value || '5') && (
                            <i
                                className="fa-solid fa-box-archive mr-1"
                                onClick={() => showDeleteModal(params.row, 'archive')}
                                style={{ cursor: 'pointer' }}
                            ></i>
                        )}
                    </>
                );
            },
        },
    ];

    function showDeleteModal(param: any, action: ActionType) {
        setSelected({ type: action, job: param });
        setConformationModal(true);
    }

    async function handleJobSetToClose() {
        const action = selected.type;
        const job = selected.job as Job;
        try {
            if (action === 'warn') {
                await getInstance().delete(`/employer/job/${job?.uuid}`);
                dispatch(showSuccessSnackbar('Job/Archive has been deleted successfully'));
            } else {
                await getInstance().post<any, any>(`/employer/job/`, { ...job, status: jobStatus.find((item) => item.label === 'Closed')?.value || '5' });
                dispatch(showSuccessSnackbar('Job/Archive has been archive successfully'));
            }
            queryClient.invalidateQueries(['jobList']);
        } catch (error) {
            dispatch(showErrorSnackbar("Can't change the Status,Please try again latter"));
        }
        setConformationModal(false);
    }
    return (
        <>
            <Grid>
                <Grid mt="2rem" container>
                    <Grid item xs={9}>
                        <Typography variant="h4">Jobs Admin</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Button variant="contained" onClick={handleCreateJob}>
                            Create Job or List
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} mt={'2rem'}>
                    <DataGrid
                        style={{
                            minHeight: 500,
                        }}
                        // pageSize={10}
                        autoHeight
                        rows={JobList.data || []}
                        columns={columns}
                        rowHeight={38}
                        disableSelectionOnClick
                    />
                </Grid>
            </Grid>
            {conformationModal && (
                <Dialog open={conformationModal} fullWidth>
                    <DialogTitle sx={{ textAlign: 'center' }}>
                        <CancelSharpIcon fontSize="large" color="primary" />
                        <Typography fontWeight="bold" variant="h5">
                            Are you sure?{' '}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography textAlign={'center'} variant="subtitle1">
                            Do you wish to {selected?.type === 'warn' ? 'delete' : 'archive'} this Job or List?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setConformationModal(false)} variant="outlined">
                            cancel
                        </Button>
                        <Button variant="contained" onClick={handleJobSetToClose}>
                            {selected?.type === 'warn' ? 'Delete' : 'Archive'}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default JobList;
