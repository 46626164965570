import React, { useMemo, useRef, useState } from 'react';
import { CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getInstance } from '../services/fetch';

const CircularProgressContainer = styled('div')`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0;
    position: relative;
`;

const LocationSelector: React.FC = () => {
    const [locationDetails, setLocationDetails] = useState<{
        country: null | { name: string; id: number; lat: null; lng: null };
        level1: null | { name: string; id: number; lat: string; lng: string };
        level2: null | { name: string; id: number; lat: string; lng: string };
        level3: null | { name: string; id: number; lat: string; lng: string };
    }>({
        country: null,
        level1: null,
        level2: null,
        level3: null,
    });

    const {
        lat,
        lng,
        id: geonameId,
    } = useMemo(() => {
        return (
            locationDetails.level3 ||
            locationDetails.level2 ||
            locationDetails.level1 ||
            (locationDetails.country && {
                ...locationDetails.country,
                lat: null,
                lng: null,
            }) || { lat: null, lng: null, id: null }
        );
    }, [locationDetails.country, locationDetails.level1, locationDetails.level2, locationDetails.level3]);

    const countriesQueryInfo = useQuery<{
        byId: Record<string, { id: number; name: string; lat: null; lng: null }>;
        allIds: string[];
    }>(
        ['countries'],
        async () => {
            const response = await getInstance().get<any>(`/public/location-options`);

            if (response.error) {
                throw new Error(response.error);
            }

            const byId: any = {};

            const allIds = response.data.map((row: any) => {
                const id = row.geonameId?.toString() || '';

                byId[id] = {
                    ...row,
                    id,
                };

                return id;
            });

            return {
                byId,
                allIds,
            };
        },
        {
            enabled: true,
            cacheTime: Infinity,
            staleTime: Infinity,
        }
    );

    const level1QueryInfo = useQuery<{
        byId: Record<string, { id: number; name: string; lat: string; lng: string }>;
        allIds: string[];
    }>(
        ['level1', locationDetails.country?.id],
        async () => {
            const response = await getInstance().get<any>(`/public/location-options?geonameId=${locationDetails.country?.id}`);

            if (response.error) {
                throw new Error(response.error);
            }

            const byId: any = {};

            const allIds = response.data.map((row: any) => {
                const id = row.geonameId?.toString() || '';

                byId[id] = {
                    ...row,
                    id,
                };

                return id;
            });

            return {
                byId,
                allIds,
            };
        },
        {
            enabled: !!locationDetails.country?.id,
            cacheTime: Infinity,
            staleTime: Infinity,
        }
    );

    const level2QueryInfo = useQuery<{
        byId: Record<string, { id: number; name: string; lat: string; lng: string }>;
        allIds: string[];
    }>(
        ['level2', locationDetails.level1?.id],
        async () => {
            const response = await getInstance().get<any>(`/public/location-options?geonameId=${locationDetails.level1?.id}`);

            if (response.error) {
                throw new Error(response.error);
            }

            const byId: any = {};

            const allIds = response.data.map((row: any) => {
                const id = row.geonameId?.toString() || '';

                byId[id] = {
                    ...row,
                    id,
                };

                return id;
            });

            return {
                byId,
                allIds,
            };
        },
        {
            enabled: !!locationDetails.level1?.id,
            cacheTime: Infinity,
            staleTime: Infinity,
        }
    );

    const level3QueryInfo = useQuery<{
        byId: Record<string, { id: number; name: string; lat: string; lng: string }>;
        allIds: string[];
    }>(
        ['level3', locationDetails.level2?.id],
        async () => {
            const response = await getInstance().get<any>(`/public/location-options?geonameId=${locationDetails.level2?.id}`);

            if (response.error) {
                throw new Error(response.error);
            }

            const byId: any = {};

            const allIds = response.data.map((row: any) => {
                const id = row.geonameId?.toString() || '';

                byId[id] = {
                    ...row,
                    id,
                };

                return id;
            });

            return {
                byId,
                allIds,
            };
        },
        {
            enabled: !!locationDetails.level2?.id,
            cacheTime: Infinity,
            staleTime: Infinity,
        }
    );

    const idPrefixRef = useRef(Math.random().toString());
    const idPrefix = idPrefixRef.current;

    return (
        <>
            <Grid item sm={12} md={3}>
                <FormControl fullWidth>
                    <InputLabel id={`${idPrefix}-country-label`}>Country</InputLabel>
                    {countriesQueryInfo.isLoading && (
                        <CircularProgressContainer>
                            <CircularProgress size="1rem" style={{ position: 'absolute', top: '1rem' }} />
                        </CircularProgressContainer>
                    )}
                    <Select
                        disabled={countriesQueryInfo.isLoading}
                        labelId={`${idPrefix}-country-label`}
                        value={locationDetails.country?.id || ''}
                        label="Country"
                        onChange={(e) => {
                            const country = countriesQueryInfo.data?.byId?.[e.target.value as string];

                            if (!country) {
                                return;
                            }

                            setLocationDetails((v) => {
                                return {
                                    ...v,
                                    country,
                                    level1: null,
                                    level2: null,
                                    level3: null,
                                };
                            });
                        }}
                    >
                        {countriesQueryInfo.data?.allIds?.map((id) => {
                            const country = countriesQueryInfo.data.byId[id];

                            return (
                                <MenuItem key={id} value={id}>
                                    {country.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>

            {locationDetails.country && (level1QueryInfo.isLoading || !!level1QueryInfo.data?.allIds.length) && (
                <Grid item sm={12} md={3}>
                    <FormControl fullWidth>
                        <InputLabel id={`${idPrefix}-level1-label`}>Level 1</InputLabel>
                        {level1QueryInfo.isLoading && (
                            <CircularProgressContainer>
                                <CircularProgress size="1rem" style={{ position: 'absolute', top: '1rem' }} />
                            </CircularProgressContainer>
                        )}
                        <Select
                            disabled={level1QueryInfo.isLoading}
                            labelId={`${idPrefix}-level1-label`}
                            value={locationDetails.level1?.id || ''}
                            label="Level 1"
                            onChange={(e) => {
                                const level1 = level1QueryInfo.data?.byId?.[e.target.value as string];

                                if (!level1) {
                                    return;
                                }

                                setLocationDetails((v) => {
                                    return {
                                        ...v,
                                        level1,
                                        level2: null,
                                        level3: null,
                                    };
                                });
                            }}
                        >
                            {level1QueryInfo.data?.allIds?.map((id) => {
                                const level1 = level1QueryInfo.data.byId[id];

                                return (
                                    <MenuItem key={id} value={id}>
                                        {level1.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            )}

            {locationDetails.level1 && (level2QueryInfo.isLoading || !!level2QueryInfo.data?.allIds.length) && (
                <Grid item sm={12} md={3}>
                    <FormControl fullWidth>
                        <InputLabel id={`${idPrefix}-level2-label`}>Level 2</InputLabel>
                        {level2QueryInfo.isLoading && (
                            <CircularProgressContainer>
                                <CircularProgress size="1rem" style={{ position: 'absolute', top: '1rem' }} />
                            </CircularProgressContainer>
                        )}
                        <Select
                            disabled={level2QueryInfo.isLoading}
                            labelId={`${idPrefix}-level2-label`}
                            value={locationDetails.level2?.id || ''}
                            label="Level 2"
                            onChange={(e) => {
                                const level2 = level2QueryInfo.data?.byId?.[e.target.value as string];

                                if (!level2) {
                                    return;
                                }

                                setLocationDetails((v) => {
                                    return {
                                        ...v,
                                        level2,
                                        level3: null,
                                    };
                                });
                            }}
                        >
                            {level2QueryInfo.data?.allIds?.map((id) => {
                                const level2 = level2QueryInfo.data.byId[id];

                                return (
                                    <MenuItem key={id} value={id}>
                                        {level2.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            )}

            {locationDetails.level2 && (level3QueryInfo.isLoading || !!level3QueryInfo.data?.allIds.length) && (
                <Grid item sm={12} md={3}>
                    <FormControl fullWidth>
                        <InputLabel id={`${idPrefix}-level3-label`}>Level 3</InputLabel>
                        {level3QueryInfo.isLoading && (
                            <CircularProgressContainer>
                                <CircularProgress size="1rem" style={{ position: 'absolute', top: '1rem' }} />
                            </CircularProgressContainer>
                        )}
                        <Select
                            disabled={level3QueryInfo.isLoading}
                            labelId={`${idPrefix}-level3-label`}
                            value={locationDetails.level3?.id || ''}
                            label="Level 3"
                            onChange={(e) => {
                                const level3 = level3QueryInfo.data?.byId?.[e.target.value as string];

                                if (!level3) {
                                    return;
                                }

                                setLocationDetails((v) => {
                                    return {
                                        ...v,
                                        level3,
                                    };
                                });
                            }}
                        >
                            {level3QueryInfo.data?.allIds?.map((id) => {
                                const level3 = level3QueryInfo.data.byId[id];

                                return (
                                    <MenuItem key={id} value={id}>
                                        {level3.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            )}

            <Grid item sm={12}>
                <div>Other info</div>
                <div>
                    <pre>
                        {JSON.stringify(
                            {
                                lat,
                                lng,
                                geonameId,
                            },
                            null,
                            2
                        )}
                    </pre>
                </div>
            </Grid>
        </>
    );
};

export default LocationSelector;
