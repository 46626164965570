import { Grid, Tabs, Tab, Typography } from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Skills from './JobSkill';
import ProfileDetails from './JobProfile';
import Attachments from './JobAttachment';
import { GenericModal } from '../../../../../components/modal';
import CommonModal from '../../../../../components/CommonModal';
import { getInstance } from '../../../../../services/fetch';
import { showWarningSnackbar } from '../../../../../redux/actions/snackbarAction';
import { useDispatch, useSelector } from 'react-redux';
import { selectedJob } from '../../../../../redux/selectors/jobSelecter';
enum TabValue {
    PERSONAL = 'personal',
    SKILLS = 'skills',
    ATTACHMENTS = 'attachments',
}

const JobProfile = () => {
    const { jobId } = useParams<{ jobId: string }>();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const job = useSelector(selectedJob);

    const [tabValue, setTabValue] = useState(TabValue.PERSONAL);
    const [open, setOpen] = useState<boolean>(false);
    const [additionalDetails, setAdditionalDetails] = useState<{ open: boolean; value: string }>({
        open: false,
        value: '',
    });

    const handleChangeTab = (_: React.SyntheticEvent, newValue: TabValue) => {
        setTabValue(newValue);
    };

    const openAdditionalDetailsModal = () => {
        setAdditionalDetails({ ...additionalDetails, open: true });
    };
    async function handleApplyForJob() {
        const response = await getInstance().post(`/seeker/job/apply/${jobId}`, {
            additionalInformation: additionalDetails.value,
            origin: window.location.origin,
        });
        if (response.error) {
            dispatch(showWarningSnackbar('Failed to apply for the job'));
            return;
        }
        setAdditionalDetails({ ...additionalDetails, open: false });
        setOpen(true);
    }

    function handleComplete() {
        setOpen(false);
        navigate('/jobs');
    }
    useEffect(() => {
        if (job.uuid === '') {
            navigate('./../');
        }
    }, [job, navigate]);
    if (!jobId) return null;
    return (
        <Grid padding={2}>
            <Grid ml={3} mr={3} mb={3}>
                <Tabs value={tabValue} onChange={handleChangeTab} centered>
                    <Tab disabled={true} label="Personal Details" value={TabValue.PERSONAL} />
                    <Tab disabled={true} label="Skills & Experience" value={TabValue.SKILLS} />
                    <Tab disabled={true} label="Attachments" value={TabValue.ATTACHMENTS} />
                </Tabs>
            </Grid>
            {tabValue === TabValue.PERSONAL && (
                <ProfileDetails
                    job={job}
                    stepper={true}
                    next={() => {
                        setTabValue(TabValue.SKILLS);
                    }}
                    prev={() => {
                        setTabValue(TabValue.PERSONAL);
                    }}
                />
            )}
            {tabValue === TabValue.SKILLS && (
                <Skills
                    stepper={true}
                    next={() => {
                        setTabValue(TabValue.ATTACHMENTS);
                    }}
                    prev={() => {
                        setTabValue(TabValue.PERSONAL);
                    }}
                />
            )}
            {tabValue === TabValue.ATTACHMENTS && (
                <Attachments
                    stepper={true}
                    next={openAdditionalDetailsModal}
                    prev={() => {
                        setTabValue(TabValue.SKILLS);
                    }}
                />
            )}

            {additionalDetails.open && (
                <GenericModal
                    title={`${job.id}, ${job.title}, ${job.city}, ${job.state}, ${job.country}`}
                    handleClose={() => {
                        setAdditionalDetails({ ...additionalDetails, open: false });
                    }}
                    handleSuccess={handleApplyForJob}
                    successLabel="Submit Application"
                    size="md"
                >
                    <>
                        <label>Additional information (optional, 200 words)</label>
                        <TextareaAutosize
                            minRows={6}
                            value={additionalDetails.value}
                            onChange={(e) => setAdditionalDetails({ ...additionalDetails, value: e.target.value })}
                            placeholder="Provide any additional information to support your application."
                            style={{ width: '98%', marginTop: '10px', resize: 'vertical' }}
                        />
                    </>
                </GenericModal>
            )}

            <CommonModal open={open} title="CONGRATULATIONS" buttonLabel="OK" handleClick={handleComplete}>
                <>
                    <p>
                        You have applied for position {job.id} {job.title}.
                    </p>
                    {/* <Typography variant="body1">We encourage all employers to provide feedback to unsuccessful candidates.</Typography>
                    <br />
                    <Typography variant="body1">
                        Please understand that not all employers will choose to provide feedback, and those that do may not have time to provide detailed or
                        personalized feedback.We hope you agree that being informed of an unsuccessful application is much better than not knowing, albeit
                        disappointing at the time.
                    </Typography> */}
                    <br />
                    <Typography variant="body1">Finally, good luck from the team at AUKUS Jobs.</Typography>
                </>
            </CommonModal>
        </Grid>
    );
};

export default JobProfile;
