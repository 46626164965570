import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Alert, Typography, Button } from '@mui/material';
import RequestSentModal from './RequestSentModal';
import { requestSubscription } from '../redux/actions/profileAction';
import { selectProfileData } from '../redux/selectors/profileSelector';

const CustomAlert = styled(Alert)`
    padding: 8px 16px;
`;

const SubscribeBanner = () => {
    const dispatch = useDispatch();
    const profileDetails = useSelector(selectProfileData);

    const [open, setOpen] = useState<boolean>(false);

    const handleSubscribe = () => {
        const { firstname, surname, email, phoneNumber: phone } = profileDetails;

        dispatch(
            requestSubscription({
                firstname,
                surname,
                email,
                phone,
            })
        );

        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <CustomAlert severity="warning" variant="filled">
                {profileDetails.requestedEmployerAccess ? (
                    <>
                        <Typography>
                            Your subscription request is currently pending. Please contact info@aukusjobs.com or +61 411 317 218 if employer functionality is
                            not enabled within 4 hours.
                        </Typography>
                        <br />
                        <Typography>
                            {`While you are waiting please feel free to explore our candidate database. NOTE - Candidate identity, contact details and CV's will
                            not be available until employer functionality is fully activated.`}
                        </Typography>
                    </>
                ) : (
                    <div>
                        <Typography>
                            You are currently viewing candidate data in test-drive mode. If you wish to view candidate identities and contact details, please
                            subscribe to the platform.
                        </Typography>
                        <br />
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button variant="contained" onClick={handleSubscribe}>
                                Request Subscription
                            </Button>
                        </div>
                    </div>
                )}
            </CustomAlert>
            <RequestSentModal open={open && profileDetails.requestedEmployerAccess!} handleClose={handleClose} />
        </>
    );
};

export default SubscribeBanner;
