import { useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export const useHistoryBack = (defaultPath: string) => {
    const navigate = useNavigate();
    const location = useLocation();

    const goBackRef = useRef(() => {
        /* */
    });

    goBackRef.current = () => {
        if ((location.state as any)?.from) {
            navigate((location.state as any).from);
        } else {
            navigate(defaultPath);
        }
    };

    return useCallback(() => {
        goBackRef.current();
    }, []);
};
