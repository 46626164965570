export interface DynamicConfig {
    apiUrl: string;
    serverUri: string;
}

export const defaultConfig: DynamicConfig = {
    apiUrl: '',
    serverUri: '',
};

export const dynamicConfigUrl = 'config.json';
