import { useMutation } from '@tanstack/react-query';
import { getInstance } from '../services/fetch';

const useAdminEmailMutation = () => {
    return useMutation(async (formData: { userIds: string[]; emailBody: string; subject: string }) => {
        const response = await getInstance().post<{ error: string; data: any }, any>(`/admin/email/send-generic`, formData);

        if (response.error) {
            throw new Error(response.error);
        }

        return response.data;
    });
};

export default useAdminEmailMutation;
