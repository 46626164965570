import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid, TextField, styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getInstance } from '../services/fetch';
import Autocomplete from '@mui/material/Autocomplete';

const CircularProgressContainer = styled('div')`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
`;

const LocationSelectorGoogle: React.FC = () => {
    const [searchText, setSearchText] = useState('');
    const [searchCommited, setSearchCommitted] = useState('');
    const [locationDetails, setLocationDetails] = useState<any>(null);

    const searchQueryInfo = useQuery(
        ['location-search', searchCommited],
        async () => {
            const response = await getInstance().get<any>(`/public/location-options-google/autocomplete/${searchCommited}`);

            if (response.error) {
                throw new Error(response.error);
            }

            return response.data;
        },
        {
            enabled: !!searchCommited,
            cacheTime: Infinity,
            staleTime: Infinity,
        }
    );

    useEffect(() => {
        const timeout = setTimeout(() => {
            setSearchCommitted(searchText);
        }, 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, [searchText]);

    const placeDetailsQueryInfo = useQuery(
        ['place-details', locationDetails?.place_id],
        async () => {
            const response = await getInstance().get<any>(`/public/location-options-google/place-details/${locationDetails?.place_id}`);

            if (response.error) {
                throw new Error(response.error);
            }

            return response.data;
        },
        {
            enabled: !!locationDetails?.place_id,
            cacheTime: Infinity,
            staleTime: Infinity,
        }
    );

    return (
        <>
            <Grid item sm={12}>
                <Autocomplete
                    options={searchQueryInfo.data || []}
                    loading={searchQueryInfo.isLoading && !!searchText}
                    loadingText="Loading..."
                    value={locationDetails}
                    onChange={(_, value) => {
                        setLocationDetails(value);
                    }}
                    getOptionLabel={(option: any) => {
                        return option.description;
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Address"
                            name="address"
                            FormHelperTextProps={{ style: { fontSize: '0.875rem' } }}
                            required
                            fullWidth
                            onChange={(event) => setSearchText(event.target.value)}
                        />
                    )}
                    inputValue={searchText}
                />
            </Grid>

            {!!locationDetails?.place_id && placeDetailsQueryInfo.isLoading ? (
                <Grid item sm={12}>
                    <CircularProgressContainer>
                        <CircularProgress />
                    </CircularProgressContainer>
                </Grid>
            ) : (
                <>
                    {(placeDetailsQueryInfo.data?.addressComponents || [])
                        .slice()
                        .reverse()
                        .map(({ long_name }: any, index: number) => {
                            return (
                                <Grid item sm={3} key={index}>
                                    <TextField fullWidth disabled value={long_name} />
                                </Grid>
                            );
                        })}
                </>
            )}

            <Grid item sm={12}>
                <div>Other info</div>
                <div>
                    <pre>{JSON.stringify(placeDetailsQueryInfo.data || null, null, 2)}</pre>
                </div>
            </Grid>
        </>
    );
};

export default LocationSelectorGoogle;
