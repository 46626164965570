export interface Nav {
    title: string;
    icon: string;
    id: string;
    path?: string;
    permission: string[];
    sub?: Sub[];
    hover?: string;
}

export interface Sub {
    label: string;
    id: string;
    ico?: string;
    path: string;
    external: boolean;
    hover?: string;
}

export const NavData: Nav[] = [
    {
        title: 'Home',
        id: 'home',
        icon: 'fa-solid fa-house',
        path: '/',
        permission: ['admin', 'employer', 'jobseeker'],
        sub: [
            {
                label: 'Why partner with us?',
                id: 'why-partner-with-us',
                ico: 'fa-solid fa-circle-info',
                path: 'https://www.aukusjobs.com',
                external: true,
            },
            {
                label: 'What our Fans say',
                id: 'what-our-fan-say',
                ico: 'fa-solid fa-circle-info',
                path: 'https://www.aukusjobs.com/what-our-fans-say ',
                external: true,
            },
            {
                label: 'Our Partners',
                id: 'our-partners',
                ico: 'fa-solid fa-circle-info',
                path: 'https://www.aukusjobs.com/our-partners ',
                external: true,
            },
            {
                label: 'Contact Us',
                id: 'contact-us',
                ico: 'fa-solid fa-circle-info',
                path: 'https://www.aukusjobs.com/contact-us ',
                external: true,
            },
        ],
    },
    {
        title: 'FOR JOBSEEKERS',
        id: 'job-seeker-operation',
        icon: 'fa-solid fa-briefcase',
        permission: ['admin', 'jobseeker'],
        sub: [
            {
                label: 'My Profile',
                id: 'my-profile',
                ico: 'fa-solid fa-circle-user',
                path: '/my-profile',
                external: false,
            },
            {
                label: 'Jobs Board',
                id: 'job-board',
                ico: 'fa-solid fa-list',
                path: '/jobs',
                external: false,
            },
            {
                label: 'Job seeker Help',
                id: 'job-seeker-help',
                ico: 'fa-solid fa-circle-info',
                path: 'https://www.aukusjobs.com/jobseeker-welcome',
                external: true,
                hover: 'Click here for tips on how to create the best possible job seeker profile',
            },
        ],
    },
    {
        title: 'FOR EMPLOYERS',
        id: 'employer-operation',
        icon: 'fa-solid fa-user',
        permission: ['employer', 'admin'],
        sub: [
            {
                label: 'Candidate Search',
                id: 'candidate-search',
                ico: 'fa-solid fa-magnifying-glass',
                path: '/employer/search',
                external: false,
            },
            { label: 'My Jobs', id: 'my-job', ico: 'fa-solid fa-briefcase', path: '/my-jobs', external: false },
            {
                label: 'Jobs Board',
                id: 'employer-job-board',
                ico: 'fa-solid fa-list',
                path: '/jobs',
                external: false,
            },
            {
                label: 'Employer Help',
                id: 'employer-help',
                ico: 'fa-solid fa-circle-info',
                path: 'https://www.aukusjobs.com/employer-welcome',
                external: true,
                hover: 'Click here for tips on how to find the right candidates quickly and accurately',
            },
        ],
    },
    {
        title: 'Site Admin',
        id: 'site-admin',
        icon: 'fa-solid fa-gear',
        permission: ['admin'],
        sub: [
            {
                label: 'Dashboard',
                id: 'dashboard',
                ico: 'fa-solid fa-gauge-simple-high',
                path: '/admin',
                external: false,
            },
            {
                label: 'Users',
                id: 'users',
                ico: 'fa-solid fa-user',
                path: '/admin/users',
                external: false,
            },
            {
                label: 'Skills',
                id: 'skills',
                ico: 'fa-solid fa-bolt',
                path: '/admin/skills',
                external: false,
            },
            {
                label: 'Organization',
                id: 'organization',
                ico: 'fa-solid fa-building',
                path: '/admin/organisations',
                external: false,
            },
            {
                label: 'Jobs',
                id: 'jobs',
                ico: 'fa-solid fa-briefcase',
                path: '/admin/jobs',
                external: false,
            },
        ],
    },
    {
        title: 'Experiments',
        id: 'experiments',
        icon: 'fa-solid fa-flask',
        permission: ['admin'],
        sub: [
            {
                label: 'Public Page',
                id: 'jobs',
                ico: 'fa-solid fa-briefcase',
                path: '/public/demo',
                external: false,
            },
        ],
    },
    {
        title: 'Archive',
        id: 'archive',
        icon: 'fa-solid fa-folder-minus',
        permission: ['admin'],
        sub: [
            {
                label: 'Candidate Search V1',
                id: 'candidate-search-v1',
                ico: 'fa-solid fa-magnifying-glass',
                path: '/v1/candidate/search',
                external: false,
            },
            {
                label: 'Shortlist',
                id: 'shortlist',
                ico: 'fa-regular fa-heart',
                path: '/shortlist',
                external: false,
            },
        ],
    },
];
