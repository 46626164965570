import React, { useState, Dispatch, SetStateAction, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Link as StyledLink,
    styled,
    Grid,
    Switch,
    FormControlLabel,
    ButtonGroup,
} from '@mui/material';
import { DataGrid, GridRowsProp, GridColDef, GridRenderCellParams, GridSelectionModel, GridFilterModel, GridSortModel } from '@mui/x-data-grid';

import { SearchRequest, ProfileResult, SearchMember } from '../types/search';
import { Shortlist } from '../types/shortlist';
import { Geography } from '../types/geography';
import { Skill } from '../types/skill';
import { updateShortlist } from '../redux/actions/shortlistAction';
import { getOrganisations } from '../redux/actions/organisationsAction';
import { selectShortlist } from '../redux/selectors/shortlistSelector';
import { mapValueToLabel } from '../utils/mapValueToLabel';
import { assessmentStatus, linkedInRequestDropDown, securityClearanceDropdown } from '../data/dropDownData';
import { Link, useLocation } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Delete as DeleteIcon, InfoOutlined } from '@mui/icons-material';
import { Profile } from '../types/CandidateProfile';
import { adminUserDelete } from '../redux/actions/adminAction';
import { formatUrl } from '../utils/url';
import { useLocalStorage } from '../hooks/useLocalStore';
import { jobShowSkill } from '../constant';
import { setPublicSearchCriteria } from '../redux/actions/publicSearchAction';
import { ViewAsColumnCandidate } from './ViewAsColumnCandiate';

const DEFAULT_FILTER_MODEL: GridFilterModel = { items: [] };
const DEFAULT_SORT_MODEL: GridSortModel = [];

enum ViewAs {
    CARD = 'CARD',
    TABLE = 'TABLE',
}

const SearchResultsTable = ({
    columns,
    rows,
    skills,
    geography,
    selectionModel,
    setSelectionModel,
    setSearchCriteria,
    searchCriteriaObject,
    searchBy = 'employer',
    setSelectedIds,
    showJobAssessment: showJobAssessment = false,
}: {
    columns: SearchRequest;
    rows: ProfileResult[];
    skills: Skill[];
    geography: Geography[];
    selectionModel: GridSelectionModel;
    setSelectionModel: Dispatch<SetStateAction<GridSelectionModel>>;
    setSearchCriteria: Dispatch<SearchRequest>;
    searchCriteriaObject: any;
    searchBy?: 'employer' | 'admin' | 'public';
    setSelectedIds?: (ids: string[]) => void;
    showJobAssessment?: boolean;
}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const shortlist = useSelector(selectShortlist);
    const [pageSize, setPageSize] = useState<number>(50);
    const [showSkills, SetShowSkills] = useLocalStorage(jobShowSkill, false);
    const skillsInSearch = columns.skills && Object.keys(columns.skills).length > 0;
    const geoInSearch = columns.geography && Object.keys(columns.geography).length > 0;
    const emptySearch = !skillsInSearch && !geoInSearch;

    const [viewAs, setViewAs] = useState<ViewAs>(ViewAs.TABLE);
    const skillsAnswersMap = new Map<number, string>([
        [5, 'Very high'],
        [4, 'High'],
        [3, 'Medium'],
        [2, 'Low'],
        [1, 'Very low'],
    ]);
    const [userToDelete, setUserToDelete] = useState<Profile | null>(null);

    const CustomDialogTitle = styled(DialogTitle)`
        text-align: center;
    `;
    const CustomIcon = styled(InfoOutlined)`
        color: #d32f2f;
    `;
    const additionalColumns = Object.keys(columns)
        .map((category) => {
            const result = [];
            if (category === 'jobId') {
                result.push(
                    {
                        field: 'job',
                        headerName: 'Suitability',
                        width: 100,
                        renderCell: (params: GridRenderCellParams<any>) => {
                            if (!params.row.job) {
                                return null;
                            }
                            return assessmentStatus.find((item) => item.value === parseInt(params.row.job))?.shortLabel || null;
                        },
                        sort: 5,
                    },
                    {
                        field: 'hasApplied',
                        headerName: 'Applicant',
                        renderCell: (params: GridRenderCellParams<any>) => {
                            if (params.row.hasApplied) {
                                return <i className="fa-regular fa-square-check"></i>;
                            }
                            return '';
                        },
                        align: 'center',
                        sort: 5,
                    }
                );
            }

            const additionalColumns = Object.keys((columns as any)[category] as SearchMember).map((key) => {
                if (category === 'skills') {
                    const skill = skills.find((skill) => skill.id === key);
                    const column = columns[category]?.[key]; // Access nested property safely
                    let sort = 1;
                    if (column) {
                        const modifier = column['modifier'];
                        if (modifier === 'MANDATORY') {
                            sort = 4;
                        } else if (modifier === 'HIGHLY_DESIRABLE') {
                            sort = 3;
                        } else if (modifier === 'DESIRABLE') {
                            sort = 2;
                        } else {
                            sort = 1;
                        }
                    }
                    return {
                        field: key,
                        headerName: skill?.display || key,
                        width: 100,
                        renderCell: (params: GridRenderCellParams<any>) => {
                            return params.formattedValue ? skillsAnswersMap.get(params.formattedValue) : '';
                        },
                        sort,
                    };
                } else if (category === 'geography') {
                    const geog = geography.find((geo) => geo.id === key);
                    return { field: key, headerName: geog?.display || key, width: 100, sort: 3 };
                }
                return undefined;
            });
            if (showSkills) {
                return [...result];
            }
            return [...result, ...additionalColumns];
        })
        .flat()
        .filter((i) => !!i)
        .sort((a, b) => (b?.sort || 0) - (a?.sort || 0)) as GridColDef[];

    const conditionalColumns = emptySearch ? [] : [{ field: 'updatedAt', headerName: 'Updated' }];

    const gridRows: GridRowsProp = rows.map((row, idx) => ({
        id: idx,
        job: row.dynamicParams?.job?.rating,
        ...row.dynamicParams.profile,
        ...row.dynamicParams.geoPrefs,
        ...row.dynamicParams.answers,
        hasApplied: row.dynamicParams.hasApplied,
        ...row,
        locationCity: row.location?.city,
        locationPostCode: row.location?.postcode,
        locationCountry: row.location?.country,
        dynamicParams: null,
    }));

    let gridColumns: GridColDef[] = [] as GridColDef[];

    const handleDelete = useCallback((user: Profile) => {
        setUserToDelete(user);
    }, []);

    const confirmDelete = useCallback(() => {
        if (userToDelete?.userId) {
            dispatch(adminUserDelete(userToDelete.userId));
        }
        setUserToDelete(null);
    }, [dispatch, userToDelete]);

    const renderActions = (params: GridRenderCellParams<Profile>) => {
        return rows.length >= 1 ? (
            <Link
                to={`/admin/users/edit/${params.row.userId}`}
                state={{
                    from: location.pathname,
                }}
            >
                <StyledLink component="span">{params.row.candidateId}</StyledLink>
            </Link>
        ) : null;
    };

    if (searchBy === 'employer') {
        gridColumns = [
            {
                field: 'candidateId',
                headerName: 'ID',
                maxWidth: 100,
                renderCell: (params: GridRenderCellParams<ProfileResult>) => (
                    <Link
                        to={`/overview/${params.row.userId}?showJobAssessment=${showJobAssessment}`}
                        state={{
                            from: location.pathname,
                        }}
                    >
                        <StyledLink component="span">{params.row.candidateId}</StyledLink>
                    </Link>
                ),
            },
            { field: 'rank', headerName: emptySearch ? 'Updated' : 'Rank', width: emptySearch ? 130 : 80 },
            ...conditionalColumns,
            ...additionalColumns,
            { field: 'firstname', headerName: 'Firstname', flex: 1 },
            {
                field: 'surname',
                headerName: 'Surname',
                flex: 1,
                renderCell: (params: GridRenderCellParams<any>) => {
                    return params.row.surname ? params.row.surname.toUpperCase() : '';
                },
            },
            {
                field: 'securityClearance',
                headerName: 'Clearance',
                renderCell: (params: GridRenderCellParams<any>) => {
                    return Array.isArray(params.row.securityClearance) ? (
                        <div>
                            {(params.row.securityClearance || []).map((option: string) => {
                                return <div key={option}>{mapValueToLabel(securityClearanceDropdown, option)}</div>;
                            })}{' '}
                        </div>
                    ) : (
                        mapValueToLabel(securityClearanceDropdown, params.row.securityClearance || '')
                    );
                },
                flex: 1,
            },
            { field: 'auCitizenshipStatus', flex: 1, headerName: 'Aust. Citizenship', width: 220 },
            { field: 'locationCity', flex: 1, headerName: 'City', width: 110 },
            { field: 'locationCountry', flex: 1, headerName: 'Country', width: 110 },
            { field: 'locationPostCode', flex: 1, headerName: 'Post Code', width: 95 },
        ];
    }

    if (searchBy === 'admin') {
        const renderLinkedInActions = (params: GridRenderCellParams<Profile>) => {
            return rows.length >= 1 ? (
                <StyledLink href={`${formatUrl(params.row?.linkedInUrl || '')}`} target="_blank">
                    {params.row.linkedInUrl ? 'Url' : ''}
                </StyledLink>
            ) : null;
        };
        0;
        gridColumns = [
            {
                field: 'delete',
                maxWidth: 20,
                headerName: '',
                sortable: false,
                filterable: false,
                renderCell: (params) => (
                    <IconButton
                        style={{ padding: 0 }}
                        onClick={() => {
                            handleDelete(params.row);
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                ),
            },
            { field: 'rank', headerName: emptySearch ? 'Last Updated' : 'Rank', width: emptySearch ? 130 : 80 },

            { field: 'candidateId', flex: 1, headerName: 'Candidate ID', sortable: true, filterable: true, renderCell: renderActions },
            ...additionalColumns,
            { field: 'firstname', flex: 1, headerName: 'Firstname', sortable: true, filterable: true },
            { field: 'surname', flex: 1, headerName: 'Surname', sortable: true, filterable: true },
            { field: 'locationCity', flex: 1, headerName: 'City', width: 110 },
            { field: 'locationCountry', flex: 1, headerName: 'Country', width: 110 },
            { field: 'locationPostCode', flex: 1, headerName: 'Post Code', width: 95 },
            { field: 'accountType', flex: 1, headerName: 'Account Type', sortable: true, filterable: true },
            { field: 'readiness', flex: 1, headerName: 'Readiness', sortable: true, filterable: true },
            { field: 'seeking', flex: 1, headerName: 'Seeking', sortable: true, filterable: true },
            {
                field: 'email',
                headerName: 'Registration Email',
                sortable: true,
                filterable: true,
                width: 250,
            },
            { field: 'skillCount', headerName: 'No. of skills', sortable: true, filterable: true, flex: 1 },
            { field: 'wizardCompleted', headerName: 'Wizard Completed', sortable: true, filterable: true, flex: 1 },
            {
                field: 'linkedInStatus',
                headerName: 'LinkedIn Status',
                sortable: true,
                filterable: true,
                renderCell: (params: GridRenderCellParams<any>) => {
                    const status = params.row.linkedInStatus;
                    return linkedInRequestDropDown.find((item) => item.value === status)?.label || '';
                },
            },
            {
                field: 'linkedInUrl',
                headerName: 'LinkedIn url',
                flex: 1,
                sortable: true,
                filterable: true,
                renderCell: renderLinkedInActions,
            },
        ];
    }

    if (searchBy === 'public') {
        // public
        gridColumns = [
            {
                field: 'candidateId',
                headerName: 'ID',
                maxWidth: 100,
                renderCell: (params: GridRenderCellParams<ProfileResult>) => (
                    <Link
                        to={`/overview/${params.row.userId}?showJobAssessment=${showJobAssessment}`}
                        state={{
                            from: location.pathname,
                        }}
                    >
                        <StyledLink component="span">{params.row.candidateId}</StyledLink>
                    </Link>
                ),
            },
            { field: 'rank', headerName: emptySearch ? 'Updated' : 'Rank', width: emptySearch ? 130 : 80 },
            ...conditionalColumns,
            ...additionalColumns,
            {
                field: 'securityClearance',
                headerName: 'Clearance',
                flex: 1,
            },
            { field: 'auCitizenshipStatus', flex: 1, headerName: 'Aust. Citizenship', width: 220 },
            { field: 'locationCity', flex: 1, headerName: 'City', width: 110 },
            { field: 'locationPostCode', flex: 1, headerName: 'Post Code', width: 95 },
            { field: 'locationCountry', flex: 1, headerName: 'Country', width: 110 },
        ];
    }

    const handlePageSizeChange = (size: number) => {
        setPageSize(size);
    };

    const handleSelectionModelChange = (ids: GridSelectionModel) => {
        const selectedIDs = new Set(ids);
        const selectedRows = gridRows.filter((row) => selectedIDs.has(row.id));
        const selectedUserIds: string[] = selectedRows.map((row) => row.userId);
        if (searchBy === 'employer') {
            const _shortlist = {
                ...shortlist,
                items: selectedUserIds,
            } as Shortlist;
            dispatch(updateShortlist(_shortlist));
        }

        if (searchBy === 'admin') {
            setSelectedIds && setSelectedIds(selectedUserIds);
        }
        setSelectionModel(ids);
    };

    useEffect(() => {
        dispatch(getOrganisations());
    }, [dispatch]);

    useEffect(() => {
        if (searchBy === 'admin') {
            dispatch(setSearchCriteria({ ...searchCriteriaObject, searchBy: 'admin' }));
        } else if (searchBy === 'employer') {
            dispatch(setSearchCriteria({ ...searchCriteriaObject, searchBy: 'employer' }));
        } else if (searchBy === 'public') {
            dispatch(setPublicSearchCriteria({ ...searchCriteriaObject, searchBy: 'public' }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const queryClient = useQueryClient();

    const FILTER_MODEL_QUERY_KEY = ['search-results-filter-model'];
    const SORT_MODEL_QUERY_KEY = ['search-results-sort-model'];

    const filterModelQuery = useQuery<GridFilterModel>(
        FILTER_MODEL_QUERY_KEY,
        () => {
            return DEFAULT_FILTER_MODEL;
        },
        {
            enabled: true,
            cacheTime: Infinity,
            staleTime: Infinity,
        }
    );

    const sortModelQuery = useQuery<GridSortModel>(
        SORT_MODEL_QUERY_KEY,
        () => {
            return DEFAULT_SORT_MODEL;
        },
        {
            enabled: true,
            cacheTime: Infinity,
            staleTime: Infinity,
        }
    );

    return (
        <>
            {!!userToDelete && (
                <Dialog open={true} fullWidth>
                    <CustomDialogTitle>
                        <CustomIcon fontSize="large" />
                        <Typography fontSize="1rem" fontWeight="bold">
                            Are you sure you want to delete this account?
                        </Typography>
                    </CustomDialogTitle>
                    <DialogContent>
                        <Typography variant="body2">This profile and all associated data will be forever deleted!</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setUserToDelete(null)} variant="outlined">
                            Cancel
                        </Button>
                        <Button onClick={confirmDelete} variant="contained">
                            I understand
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <Grid container>
                <Grid item xs={4}></Grid>
                <Grid item xs={4} display={'flex'}>
                    <>
                        <FormControlLabel
                            value="skillsDisplay"
                            control={<Switch checked={showSkills} onChange={() => SetShowSkills(!showSkills)} color="default" />}
                            label="Show skills and experience"
                            labelPlacement="start"
                        />
                        <p className="ml-1">Show candidate details</p>
                    </>
                </Grid>
                <Grid item xs={4}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <ButtonGroup variant="outlined">
                            <Button onClick={() => setViewAs(ViewAs.CARD)}>Card View</Button>
                            <Button onClick={() => setViewAs(ViewAs.TABLE)}>Table View</Button>
                        </ButtonGroup>
                    </div>
                </Grid>
            </Grid>
            {viewAs === ViewAs.TABLE && (
                <DataGrid
                    filterModel={filterModelQuery.data || DEFAULT_FILTER_MODEL}
                    onFilterModelChange={(model) => {
                        queryClient.setQueryData(FILTER_MODEL_QUERY_KEY, model);
                    }}
                    sortModel={sortModelQuery.data || DEFAULT_SORT_MODEL}
                    onSortModelChange={(model) => {
                        queryClient.setQueryData(SORT_MODEL_QUERY_KEY, model);
                    }}
                    columnVisibilityModel={{
                        firstname: showSkills || searchBy === 'admin',
                        surname: showSkills || searchBy === 'admin',
                        auCitizenshipStatus: showSkills || searchBy === 'admin',
                        locationCity: showSkills || searchBy === 'admin',
                        locationPostCode: showSkills || searchBy === 'admin',
                        locationCountry: showSkills || searchBy === 'admin',
                    }}
                    density="compact"
                    pageSize={pageSize}
                    rows={gridRows}
                    columns={gridColumns}
                    selectionModel={selectionModel}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    getRowHeight={() => 'auto'}
                    onPageSizeChange={handlePageSizeChange}
                    onSelectionModelChange={handleSelectionModelChange}
                    autoHeight
                    checkboxSelection={!showJobAssessment}
                    disableSelectionOnClick
                    getRowClassName={(params) => {
                        if (params.row.hasApplied) {
                            return 'has-applied-job';
                        }
                        return '';
                    }}
                />
            )}
            {viewAs === ViewAs.CARD && <ViewAsColumnCandidate rows={gridRows} searchRequest={columns} skills={skills} />}
        </>
    );
};
export default React.memo(SearchResultsTable);
