import React, { useEffect, useState } from 'react';
import { Divider, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import Description from './Description';
import Skills from './Skills';
import Settings from './Settings';
import { validateDescription, validateSetting } from '../../../../utils/validation/shortlist';
import { Errors } from '../../../../types/shortlist';
import { getInstance } from '../../../../services/fetch';
import { ISettings, IDescription } from '../../../../types/shortlist';
import { showSuccessSnackbar, showWarningSnackbar } from '../../../../redux/actions/snackbarAction';
import { useDispatch, useSelector } from 'react-redux';
import { clearSearch, setSearchCriteria } from '../../../../redux/actions/searchAction';
import { SearchRequest } from '../../../../types/search';
import { getSkillsFramework } from '../../../../redux/actions/skillsAction';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import dayjs from 'dayjs';
import { selectProfileData } from '../../../../redux/selectors/profileSelector';
import { getUserOrganization } from '../../../../redux/actions/organisationsAction';
import { tabPermission } from '../../../../utils/permission';
import { selectIsUserAdmin } from '../../../../redux/selectors/userSelector';
import Applicants from './Applicants';
import { jobDataKEY, jobShowSkill } from '../../../../constant';
import { useLocalStorage } from '../../../../hooks/useLocalStore';

enum TabValue {
    DESCRIPTION = 'description',
    SKILLS = 'skills',
    SETTINGS = 'settings',
    Applicants = 'applicants',
}

export interface JObFormData {
    [TabValue.SETTINGS]?: ISettings;
    [TabValue.DESCRIPTION]?: IDescription;
    [TabValue.SKILLS]?: SearchRequest;
    [TabValue.Applicants]?: null;
}

const JobNew: React.FC = () => {
    const [tabValue, setTabValue] = useState(TabValue.SETTINGS);
    const [form, setForm] = useState<JObFormData>({});
    const [errors, setError] = useState<Errors>({});
    // only going to access the setter, won't use the getter
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [jobDetails, setJobDetails] = useLocalStorage(jobDataKEY, {});
    const [from, setFrom] = useLocalStorage('from', '');

    const navigate = useNavigate();
    const { jobId } = useParams();
    const dispatch = useDispatch();
    const profileData = useSelector(selectProfileData);
    const settings = form.settings as ISettings;
    const isOwner = settings?.owner === profileData?.userId;
    const location = useLocation();

    let isAuthor;
    if (profileData.userId) {
        isAuthor = settings?.sharedTo.includes(profileData?.userId);
    }
    const isAdmin = useSelector(selectIsUserAdmin);

    const jobQueryInfo = useQuery(
        ['job', jobId],
        async () => {
            const response = await getInstance().get<{ data: any; error: string }>(`/employer/job/${jobId}`);
            if (response.error) {
                throw new Error(response.error);
            }
            const data = response.data;
            const settings = {
                uuid: data.uuid,
                id: data.id || '',
                organization: data.organization || '',
                owner: data.owner || '',
                createdDate: dayjs(data.createdDate).format('DD MMM YYYY') || '',
                referenceNumber: data.referenceNumber || '',
                status: data.status || 1,
                additionalSettingNotes: data.additionalSettingNotes || '',
                sharedTo: data.sharedTo || [],
            } as ISettings;
            const description = {
                title: data.title || '',
                securityClearance: data.securityClearance || [],
                country: data.country || '',
                state: data.state || '',
                city: data.city || '',
                postcode: data.postcode || '',
                startDate: data.startDate || '',
                endDate: data.endDate || '',
                salaryRange: data.salaryRange || '',
                roleDescription: data.roleDescription || '',
                keyRequirement: data.keyRequirement || '',
                additionalDescriptionNotes: data.additionalDescriptionNotes || '',
                workingArrangement: data.workingArrangement || [],
                employmentModal: data.employmentModal || [],
                contractExtension: data.contractExtension || '',
                aboutCompany: data.aboutCompany || '',
                citizenshipStatus: data.citizenshipStatus || '',
                closingDate: data.closingDate,
            } as IDescription;
            const skills = { skills: {}, profile: {}, geography: {}, searchBy: 'employer', jobId: data.id, ...data.skills } as SearchRequest;
            const payload = { settings, description, skills };
            setForm(payload);
            // save the form data to local Storage
            setJobDetails(payload);
            return response.data;
        },
        {
            enabled: !!jobId,
            cacheTime: 1000,
            staleTime: 1000,
        }
    );

    useEffect(() => {
        if (!jobQueryInfo.isLoading && !jobQueryInfo.data) {
            navigate(-1);
        }
        const from = (location.state as { from: string })?.from || undefined;
        if (from) {
            setFrom(from);
        }
    }, [jobQueryInfo.data, jobQueryInfo.isLoading, location.state, navigate, setFrom]);

    useEffect(() => {
        const data = form.description;
        const { errors: newError } = validateDescription(form.settings as ISettings, data as IDescription);
        setError((errors) => {
            return { ...errors, description: newError };
        });
    }, [form.description, form.settings]);

    useEffect(() => {
        dispatch(getSkillsFramework());
        dispatch(getUserOrganization());
        return () => {
            localStorage.removeItem(jobShowSkill);
            dispatch(clearSearch());
        };
    }, [dispatch]);

    const handleChangeTab = (_: React.SyntheticEvent, newValue: TabValue) => {
        setTabValue(newValue);
    };
    const handleChange = async (type: TabValue, data: any) => {
        if (type === TabValue.Applicants) {
            return;
        }
        const {
            target: { name, value },
        } = data;
        if (type === TabValue.SKILLS) {
            setForm({ ...form, skills: value });
            dispatch(setSearchCriteria({ ...value }));
            return;
        }

        setForm({ ...form, [type]: { ...form[type], [name]: value } });
        const selectedError = errors[type];
        if (!selectedError || selectedError.length === 0) {
            return;
        }
        const newError = selectedError.filter((item) => item.name !== name);
        setError({ ...errors, [type]: newError });
    };

    const submit = async (data: any) => {
        return await getInstance().post<any, any>(`/employer/job/`, { ...data, uuid: jobId });
    };

    const validate = async (type: TabValue) => {
        const data = form[type];
        let status = false;
        if (type === TabValue.SETTINGS) {
            const { status: errorStatus, errors: newError } = validateSetting(data as ISettings);
            setError({ ...errors, settings: newError });
            status = errorStatus;
            if (!status) {
                const response = await submit(data);
                if (response.data.success) {
                    dispatch(showSuccessSnackbar('Settings Updated Successfully'));
                    setTabValue(TabValue.DESCRIPTION);
                    return;
                }
                dispatch(showWarningSnackbar("Can't update the setting  please try again latter"));
                return;
            }
            dispatch(showWarningSnackbar('Please provide the missing information'));
        }

        if (type === TabValue.DESCRIPTION) {
            const { status: errorStatus, errors: newError } = validateDescription(form.settings as ISettings, data as IDescription);
            setError({ ...errors, description: newError });
            status = errorStatus;
            if (!status) {
                const response = await submit(data);
                if (response.data.success) {
                    dispatch(showSuccessSnackbar('Description Updated Successfully'));
                    setTabValue(TabValue.SKILLS);
                    return;
                }
                dispatch(showWarningSnackbar("Can't update the description please try again latter"));
                return;
            }
            dispatch(showWarningSnackbar('Please provide the missing information'));
        }
        if (type === TabValue.SKILLS) {
            const response = await submit({ skills: data });
            if (response.data.success) {
                dispatch(showSuccessSnackbar('Configuration has been Updated Successfully'));
                return;
            }
            dispatch(showWarningSnackbar("Can't update the configuration please try again latter"));
            return;
        }
        return status;
    };
    return (
        <Grid>
            <Grid container xs={12}>
                <Grid item xs={2}>
                    <Typography variant="h5" p={2}>
                        <IconButton
                            color="primary"
                            aria-label="back"
                            onClick={() => {
                                navigate(from);
                            }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        {form?.settings?.id}
                    </Typography>
                </Grid>
                <Grid xs={10}>
                    <Typography variant="h5" color={'primary'} p={2}>
                        {`${form.description?.title} ${form.description?.city ? ',' : ''} ${form.description?.city} ${form.description?.city ? ',' : ''} ${
                            form.description?.state
                        } ${form.description?.securityClearance && form.description?.securityClearance.length ? ',' : ''} ${
                            form.description?.securityClearance
                        }`}
                    </Typography>
                </Grid>
            </Grid>

            <Divider></Divider>
            <Grid ml={3} mr={3} mb={3}>
                <Tabs value={tabValue} onChange={handleChangeTab} centered>
                    <Tab label="Settings" value={TabValue.SETTINGS} />
                    <Tab label="Job Description" value={TabValue.DESCRIPTION} />
                    {tabPermission({ isAdmin, isOwner, isAuthor }) && <Tab label="ShortList" value={TabValue.SKILLS} />}
                    {tabPermission({ isAdmin, isOwner, isAuthor }) && <Tab label="Applicants" value={TabValue.Applicants} />}
                </Tabs>
            </Grid>
            {tabValue === TabValue.SETTINGS && (
                <Settings errors={errors} values={form} setValue={(event) => handleChange(TabValue.SETTINGS, event)} save={() => validate(TabValue.SETTINGS)} />
            )}
            {tabValue === TabValue.DESCRIPTION && (
                <Description
                    errors={errors}
                    values={form}
                    setValue={(value) => handleChange(TabValue.DESCRIPTION, value)}
                    save={() => validate(TabValue.DESCRIPTION)}
                />
            )}

            {tabValue === TabValue.SKILLS && (
                <Skills values={form} setValue={(value) => handleChange(TabValue.SKILLS, value)} save={() => validate(TabValue.SKILLS)} />
            )}

            {tabValue === TabValue.Applicants && <Applicants />}
        </Grid>
    );
};

export default JobNew;
