import { AppDispatch } from '../store';

export const showErrorSnackbar = (message: string | JSX.Element) => {
    return (dispatch: AppDispatch) => {
        dispatch({ type: 'SNACKBAR_ERROR', message });
    };
};

export const showWarningSnackbar = (message: string | JSX.Element) => {
    return (dispatch: AppDispatch) => {
        dispatch({ type: 'SNACKBAR_WARNING', message });
    };
};

export const showInfoSnackbar = (message: string | JSX.Element, autoClear = false, timeOut = 5000) => {
    if (autoClear) {
        return (dispatch: AppDispatch) => {
            dispatch({ type: 'SNACKBAR_INFO', message });
            setTimeout(() => {
                dispatch({ type: 'SNACKBAR_CLEAR' });
            }, timeOut);
        };
    }
    return (dispatch: AppDispatch) => {
        dispatch({ type: 'SNACKBAR_INFO', message });
    };
};

export const showSuccessSnackbar = (message: string | JSX.Element) => {
    return (dispatch: AppDispatch) => {
        dispatch({ type: 'SNACKBAR_SUCCESS', message });
    };
};

export const clearSnackbar = () => {
    return (dispatch: AppDispatch) => {
        dispatch({ type: 'SNACKBAR_CLEAR' });
    };
};
