import styled from 'styled-components';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from '@mui/material';

interface RequestSentModalProps {
    open: boolean;
    handleClose: () => void;
}

const CustomTypography = styled(Typography)`
    font-size: 1rem;
`;

const RequestSentModal = ({ open, handleClose }: RequestSentModalProps) => {
    return (
        <Dialog open={open} fullWidth>
            <DialogTitle sx={{ textAlign: 'center' }}></DialogTitle>
            <DialogContent>
                <CustomTypography variant="body2">
                    Your request for employer access has been sent to our administrator. When access has been provided you will be invited to log back in to
                    enable full employer functionality.
                </CustomTypography>
                <br />
                <CustomTypography variant="body2">
                    Please contact support on +61 411 317 218,
                    <Link underline="none" href="mailto:info@aukusjobs.com">
                        {' '}
                        info@aukusjobs.com{' '}
                    </Link>
                    if access is not provided within 4 hours (or sooner if access is urgently required).
                </CustomTypography>
            </DialogContent>
            <DialogActions>
                <Box margin={'1rem'}>
                    <Button variant="contained" onClick={handleClose}>
                        Continue To View Anonymous Candidate Data
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default RequestSentModal;
