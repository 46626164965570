import { RootState } from '../store';

export const selectUser = (state: RootState) => state.user;
export const selectUserToken = (state: RootState) => state.user.token;
export const selectIsUserAdmin = (state: RootState) => userInGroup(state, 'admin');
export const selectIsUserEmployer = (state: RootState) => userInGroup(state, 'employer');

const userInGroup = (state: RootState, group: string) => {
    try {
        if ((state?.user?.token?.['cognito:groups'] || []).includes(group)) {
            return true;
        }
    } catch {
        //
    }
    return false;
};
