import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { clearVerifier, getChallenge } from '../../utils/pkce';
import { useLocation, useNavigate } from 'react-router-dom';
import { initiateAuth } from '../../services/auth';
import { setup, setupWithToken } from '../../services/fetch';
import { AuthContext } from '../../auth/AuthProvider';
import { useConfig } from '../../config/ConfigProvider';
import { getUserInfo } from '../../redux/actions/userAction';
import Loader from '../../components/loader';

const Root = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    flex-direction: column;
    font-size: 30px;

    @keyframes flickerAnimation {
        /* flame pulses */
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.1;
        }
        100% {
            opacity: 1;
        }
    }
`;

const StyledLogo = styled.div`
    margin-bottom: 40px;
`;

const StyledAnimatedLogo = styled(StyledLogo)`
    animation: flickerAnimation 2.5s infinite;
`;

const PleaseRetry = styled(Button)`
    && {
        text-align: center;
        margin-left: 0;
    }
`;

const LANDING_REDIRECT = 'LR'; // this is used to prevent a double re-run of initiateAuth due to callback from cognito

const Landing: React.FC = () => {
    const { config } = useConfig();
    const location = useLocation();
    const [error, setError] = useState<string | undefined>(undefined);
    const [waitingForRetry, setWaitingForRetry] = useState<boolean>(false);
    const dispatch = useDispatch();
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();

    const handleRetry = useCallback(
        (redirect?: boolean) => {
            sessionStorage.removeItem(LANDING_REDIRECT);
            if (redirect) {
                navigate('/');
            }
        },
        [navigate]
    );

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        const landingRedirect = false && sessionStorage.getItem(LANDING_REDIRECT);

        if (!params.error && authContext.authStatus === 2 && !landingRedirect) {
            sessionStorage.setItem(LANDING_REDIRECT, String(Math.random()));
            if (config?.apiUrl) {
                const _getChallenge = async () => {
                    setup(config.apiUrl);
                    const challenge = await getChallenge();
                    const { status, data, error: _error } = await initiateAuth(config.serverUri, challenge);
                    if (status === 200) {
                        clearVerifier();
                        setupWithToken(config.apiUrl, data.token);
                        authContext.setToken(data.token);
                        dispatch(getUserInfo());
                    }
                    if (_error) {
                        setError(_error);
                    }
                };
                _getChallenge();
            } else {
                setWaitingForRetry(true);
                // // retry to see if context has be set
                setTimeout(handleRetry, 300);
            }
        } else {
            if (landingRedirect && !params.search) {
                setError('Please try again');
            } else if (params.error) {
                setError(params.error);
            }
        }
    }, [authContext, config, location, error, handleRetry, dispatch]);

    const { user } = authContext;

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (!user) {
                return;
            }

            if (user.accountType === 'employer') {
                navigate('/employer/search', { state: { authProvider: true } });
                return;
            }

            if (!user.wizardCompleted) {
                navigate('/account', { state: { authProvider: true } });
                return;
            }

            navigate('/my-profile', { state: { authProvider: true } });
        }, 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, [authContext, navigate, user]);

    if (error && !waitingForRetry) {
        return (
            <Root>
                <StyledLogo />
                <p>Oops, something went wrong!</p>
                <PleaseRetry
                    onClick={() => {
                        handleRetry(true);
                    }}
                >
                    Retry
                </PleaseRetry>
            </Root>
        );
    }

    return (
        <React.Fragment>
            <Root>
                {!user ? (
                    <>
                        <StyledAnimatedLogo />
                        <Loader />
                    </>
                ) : (
                    <>
                        <StyledLogo />
                        <Loader />
                    </>
                )}
            </Root>
        </React.Fragment>
    );
};

export default Landing;
