import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, AccordionSummary, Typography, AccordionDetails, RadioGroup, FormControlLabel, Radio, Button, Divider, Grid } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { geoRadio } from '../../data/dropDownData';
import { GeographyPrefs } from '../../types/geography';
import { getGeographyFramework, getGeographyPrefs, upsertGeographyPrefs } from '../../redux/actions/geographyAction';
import { selectGeographyData, selectGeographyPreferances } from '../../redux/selectors/geographySelector';
import { showSuccessSnackbar } from '../../redux/actions/snackbarAction';

function GeographicInfo() {
    const dispatch = useDispatch();
    const geography = useSelector(selectGeographyData);
    const geoPrefs = useSelector(selectGeographyPreferances);

    useEffect(() => {
        dispatch(getGeographyFramework());
        dispatch(getGeographyPrefs());
    }, [dispatch]);

    function createAccordionGroup(parentGeo: string | undefined, level = 0) {
        const geoArray = geography.filter((geo) => (geo.parents || []).includes(parentGeo || '') || parentGeo === geo.parents) || [];

        return geoArray.map((geo, idx) => {
            const hasChildren = geography.find((child) => (child.parents || []).includes(geo.id));

            return hasChildren ? (
                <Accordion key={`${idx}-${level}`} elevation={level === 0 ? 1 : 0}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>{geo.display}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {createAccordionGroup(geo.id, level + 1).map((child, idx2) => (
                            <div key={idx2}>{child}</div>
                        ))}
                    </AccordionDetails>
                </Accordion>
            ) : (
                <Grid sx={{ flexGrow: 1 }} container spacing={1} padding={0} justifyContent="flex-start" alignItems="center">
                    <Grid item xs={1} />
                    <Grid item xs={3}>
                        <Typography>{geo.display}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <RadioGroup row name={geo.id} onChange={handleRadioChange} value={geoPrefs[geo.id]?.suitability || ''}>
                            {geoRadio.map((record, idx2) => (
                                <FormControlLabel control={<Radio />} key={idx2} value={record.value} label={record.label} />
                            ))}
                        </RadioGroup>
                    </Grid>
                </Grid>
            );
        });
    }

    const onSubmit = async () => {
        dispatch(showSuccessSnackbar('Saved successfully!'));
        dispatch(upsertGeographyPrefs(geoPrefs));
    };

    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const newGeoTable: GeographyPrefs = {
            ...geoPrefs,
            [name]: {
                suitability: value as any,
            },
        };

        dispatch(upsertGeographyPrefs(newGeoTable));
    };

    const accordionGroup = createAccordionGroup(undefined);

    return (
        <>
            <Typography variant="h3" p={2}>
                Geo-Preferences
            </Typography>

            <Divider></Divider>
            <br />

            <Grid sx={{ flexGrow: 1 }} container spacing={2} padding={1}>
                {/* Row 1 */}
                <Grid item xs={12}>
                    {accordionGroup}
                </Grid>
                {/* Row 2 */}
                <Grid item xs={12}>
                    <Button onClick={onSubmit} variant="contained">
                        Save
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default GeographicInfo;
