interface Message {
    employerFirstName: string;
    employerSurname: string;
    employerEmail: string;
    employerNumber: string;
    organisationName: string;
}

export const pingMessage = (data: Partial<Message>) => `Hi there.\n\n\
My name is ${data.employerFirstName} ${data.employerSurname} representing ${
    data.organisationName
}. I found your skills profile within the AUKUS Jobs platform and would like to explore an opportunity with you.\n\n\
I am currently seeking someone with XXX, YYY, ZZZ skills and experience for a role in XXX (location). The role will start in XXX, and will require a XXX security clearance.\n\n\
If this opportunity appeals to you, (or you would like to explore other opportunities with ${data.organisationName}) please contact me directly via ${
    data.employerEmail
} ${data.employerNumber ? `or ${data.employerNumber} ` : ''} to establish contact and discuss further.\n\n\
Kind Regards -  ${data.employerFirstName} ${data.employerSurname}`;

interface FeedbackMessage {
    employer: {
        name: string;
    };
    candidate: {
        name: string;
    };
    job: {
        id: string;
        name: string;
        city: string;
    };
}
export const feedbackMessage = ({ employer, candidate, job }: FeedbackMessage) => {
    return `Dear ${candidate?.name},

    Your application for Job ${job?.id}, ${job?.name} in ${job?.city} has been considered by our recruiting team. Unfortunately your profile is not considered competitive for this role at this time. 
    
    We wish you the best of luck in your search for suitable employment.
    
    ${employer?.name}`;
};
