import { getInstance } from '../../services/fetch';
import { Geography, GeographyPrefs } from '../../types/geography';
import { AppDispatch } from '../store';
import { showErrorSnackbar } from './snackbarAction';

function compare(a: any, b: any) {
    if (a.display < b.display) {
        return -1;
    }
    if (a.display > b.display) {
        return 1;
    }
    return 0;
}

export const getGeographyFramework = () => async (dispatch: AppDispatch) => {
    try {
        const response = await getInstance().get<{ data: Geography[]; error: string }>(`/geography`);
        dispatch({
            type: 'GET_GEOGRAPHY',
            payload: response.data.sort(compare),
        });
    } catch (e: any) {
        dispatch({
            type: 'GET_GEOGRAPHY_ERROR',
            payload: e?.message,
        });
        console.error('Get Geography error');
        dispatch(showErrorSnackbar(`An error occurred while getting the geography framework`));
    }
};

export const getGeographyPrefs = () => async (dispatch: AppDispatch) => {
    try {
        const response = await getInstance().get<{ data: GeographyPrefs; error: string }>(`/seeker/geography`);
        dispatch({
            type: 'GET_GEOGRAPHY_PREFS',
            payload: response.data,
        });
    } catch (e: any) {
        dispatch({
            type: 'GET_GEOGRAPHY_PREFS_ERROR',
            payload: e?.message,
        });
        console.error('Get Geography prefs error');
        dispatch(showErrorSnackbar(`An error occurred while getting the geography preferences`));
    }
};

export const upsertGeographyPrefs = (newPrefs: GeographyPrefs) => async (dispatch: AppDispatch) => {
    try {
        await getInstance().put<{ error: string }, any>(`/seeker/geography`, newPrefs);
        await getGeographyPrefs()(dispatch);
    } catch (e: any) {
        dispatch({
            type: 'GEOGRAPHY_UPSERT_ERROR',
            payload: e?.message,
        });
        dispatch(showErrorSnackbar(`An error occurred while upserting the geography preferences`));
    }
};
