import { Typography, Link as StyledLink, Grid, Button, ButtonGroup } from '@mui/material';
import React, { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getInstance } from '../../../../services/fetch';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganisations } from '../../../../redux/actions/organisationsAction';
import { selectOrganisationsData } from '../../../../redux/selectors/organisationsSelector';
import { ViewAsColumnJob } from '../../../../components/ViewAsColumnJob';

enum ViewAs {
    CARD = 'CARD',
    TABLE = 'TABLE',
}

const JobBoard: React.FC = () => {
    const dispatch = useDispatch();
    const organizations = useSelector(selectOrganisationsData);

    const [viewAs, setViewAs] = React.useState<ViewAs>(ViewAs.TABLE);

    useEffect(() => {
        dispatch(getOrganisations());
    }, [dispatch]);
    const JobQuery = useQuery(
        ['job'],
        async () => {
            const response = await getInstance().get<{ data: any; error: string }>(`/public/job`);
            if (response.error) {
                throw new Error(response.error);
            }
            return response.data;
        },
        {
            cacheTime: 1000,
            staleTime: 1000,
        }
    );

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 1,
            renderCell: (params: any) => (
                <Link
                    to={`/jobs/${params.row.uuid}`}
                    state={{
                        from: location.pathname,
                    }}
                >
                    <StyledLink component="span">{params.row.id}</StyledLink>
                </Link>
            ),
        },
        {
            field: 'title',
            headerName: 'Title',
            flex: 1,
        },
        {
            field: 'organization',
            headerName: 'Organization',
            renderCell: (params: any) => <Typography>{(organizations || [])?.find((item) => item.id === params.row.organization)?.name}</Typography>,
            flex: 1,
        },
        {
            field: 'city',
            headerName: 'City',
            flex: 1,
        },
        {
            field: 'country',
            headerName: 'Country',
            flex: 1,
        },
        {
            field: 'minSecClearance',
            headerName: 'Min. Sec. Clearance',
            flex: 1,
            renderCell: (params: any) => {
                const securityClearance = params.row.securityClearance || [];
                if (Array.isArray(securityClearance)) {
                    return securityClearance?.map((item: string) => <Typography key={item}>{item}</Typography>);
                } else {
                    return <Typography>{securityClearance}</Typography>;
                }
            },
        },
    ];

    return (
        <>
            <Grid mt="2rem" container>
                <Grid item xs={9}>
                    <Typography variant="h4">Jobs Board</Typography>
                </Grid>
            </Grid>
            <Grid container display={'flex'} justifyContent={'space-around'}>
                {/* <Grid item xs={3} mt={'3rem'}>
                    <Typography variant="h6">Filter by:</Typography>
                    <Typography variant="body1">City</Typography>
                    <Typography variant="body1">Country</Typography>
                    <Typography variant="body1">Organization</Typography>
                    <Typography variant="body1">Security Clearance</Typography>
                </Grid> */}
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={4}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <ButtonGroup variant="outlined">
                                    <Button onClick={() => setViewAs(ViewAs.CARD)}>Card View</Button>
                                    <Button onClick={() => setViewAs(ViewAs.TABLE)}>Table View</Button>
                                </ButtonGroup>
                            </div>
                        </Grid>
                    </Grid>
                    {viewAs === ViewAs.TABLE && (
                        <Grid item xs={12} mt={'2rem'}>
                            <DataGrid
                                style={{
                                    minHeight: 500,
                                }}
                                pageSize={10}
                                autoHeight
                                rows={JobQuery.data || []}
                                columns={columns}
                                rowHeight={38}
                                disableSelectionOnClick
                            />
                        </Grid>
                    )}
                    {viewAs === ViewAs.CARD && <ViewAsColumnJob rows={JobQuery.data || []} />}
                </Grid>
            </Grid>
        </>
    );
};

export default JobBoard;
