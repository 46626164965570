import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import styled from 'styled-components';
import { useEffect, useState } from 'react';

interface TakeABreakModalProps {
    open: boolean;
    onCancel: () => void;
    handleProceedDelete: () => void;
    handleTakeABreak: () => void;
}

const CustomDialogTitle = styled(DialogTitle)`
    text-align: center;
`;

const CustomIcon = styled(InfoOutlined)`
    color: #d32f2f;
`;

enum Answer {
    HIDE = 'HIDE',
    DELETE = 'DELETE',
    CANCEL = 'CANCEL',
}

const TakeABreakModal = ({ open, onCancel, handleProceedDelete, handleTakeABreak }: TakeABreakModalProps) => {
    const [answer, setAnswer] = useState(Answer.HIDE);

    useEffect(() => {
        if (!open) {
            setAnswer(Answer.HIDE);
        }
    }, [open]);

    const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAnswer(event.currentTarget.value as Answer);
    };

    const handleSubmitAnswer = () => {
        if (answer === Answer.DELETE) {
            handleProceedDelete();
            return;
        }

        if (answer === Answer.HIDE) {
            handleTakeABreak();
            return;
        }

        if (answer === Answer.CANCEL) {
            onCancel();
            return;
        }
    };

    return (
        <>
            <Dialog open={open} fullWidth>
                <CustomDialogTitle>
                    <CustomIcon fontSize="large" />
                    <Typography fontSize="1rem" fontWeight="bold">
                        TAKE CARE - YOUR NEXT MOVE IS IMPORTANT
                    </Typography>
                </CustomDialogTitle>
                <DialogContent>
                    <RadioGroup row={false} value={answer} onChange={handleAnswerChange}>
                        <FormControlLabel
                            value={Answer.HIDE}
                            control={<Radio />}
                            label={`Hide my profile - this will set your jobseeking status to "Not currently seeking work". (You will not be findable by employers)`}
                        />
                        <FormControlLabel
                            value={Answer.DELETE}
                            control={<Radio />}
                            label="Delete my account - This will delete all records of you and your skills."
                        />
                        <FormControlLabel value={Answer.CANCEL} control={<Radio />} label="Cancel - I changed my mind." />
                    </RadioGroup>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleSubmitAnswer} disabled={!answer}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TakeABreakModal;
