export const LIST_MAP = {
    OPEN: {
        skills: 'EXPOSED',
        identity: 'HIDDEN (until connection)',
    },
    SEEKING: {
        skills: 'EXPOSED',
        identity: 'EXPOSED',
    },
    NOT_SEEKING: {
        skills: 'HIDDEN',
        identity: 'HIDDEN',
    },
    EMPTY: {
        skills: '',
        identity: '',
    },
};

export const HELPER_TEXT = 'This field is required';
