import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import styled from 'styled-components';

interface DeleteAccountProps {
    open: boolean;
    handleCloseModal: () => void;
    handleDeleteProfile: () => void;
}

const CustomDialogTitle = styled(DialogTitle)`
    text-align: center;
`;

const CustomIcon = styled(InfoOutlined)`
    color: #d32f2f;
`;

const DeleteAccount = ({ open, handleCloseModal, handleDeleteProfile }: DeleteAccountProps) => {
    return (
        <>
            <Dialog open={open} fullWidth>
                <CustomDialogTitle>
                    <CustomIcon fontSize="large" />
                    <Typography fontSize="1rem" fontWeight="bold">
                        Are you sure you want to delete your account?
                    </Typography>
                </CustomDialogTitle>
                <DialogContent>
                    <Typography variant="body2">Your profile and all associated data will be forever deleted!</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteProfile} variant="contained">
                        I understand
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DeleteAccount;
