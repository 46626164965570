import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userMetricsSelector } from '../redux/selectors/adminSelector';
import { getAdminUserMetrics } from '../redux/actions/adminAction';
import { profileReadiness } from '../data/dropDownData';

export const useUserMetrics = () => {
    const dispatch = useDispatch();
    const metrics = useSelector(userMetricsSelector);

    useEffect(() => {
        dispatch(getAdminUserMetrics());
    }, [dispatch]);

    return {
        seeking: {
            label: `${profileReadiness.find((r) => r.value === 'SEEKING')?.label}:`,
            value: metrics.SEEKING || 0,
        },
        open: {
            label: `${profileReadiness.find((r) => r.value === 'OPEN')?.label}:`,
            value: metrics.OPEN || 0,
        },
        notSeeking: {
            label: `${profileReadiness.find((r) => r.value === 'NOT_SEEKING')?.label}:`,
            value: metrics.NOT_SEEKING || 0,
        },
        unknown: {
            label: 'Unknown:',
            value: metrics.null || 0,
        },
        isNull: !!metrics.null,
    };
};
