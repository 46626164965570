import { getInstance } from '../../services/fetch';
import { ProfileFilesResponse } from '../../types/CandidateProfile';
import { AppDispatch } from '../store';
import { showErrorSnackbar, showSuccessSnackbar } from './snackbarAction';
import { getErrorMessage } from '../../utils/getErrorMessage';

export const getFiles = () => async (dispatch: AppDispatch) => {
    try {
        const { data } = await getInstance().get<{ data: ProfileFilesResponse; error: string }>(`/seeker/profile/files/`);

        dispatch({
            type: 'GET_FILES',
            payload: data?.files || [],
        });
    } catch (e) {
        dispatch({
            type: 'GET_FILES_ERROR',
            payload: getErrorMessage(e),
        });
        console.error('Get files error');
        dispatch(showErrorSnackbar(`An error occurred while getting the files`));
    }
};

export const uploadFile = (data: FormData) => async (dispatch: AppDispatch) => {
    try {
        dispatch({ type: 'UPLOADING_FILE', payload: true });
        await getInstance().post<{ error: string }, any>(`/seeker/profile/files/upload`, data, { isForm: true });
        dispatch({ type: 'UPLOADING_FILE', payload: false });
        dispatch(showSuccessSnackbar(`Uploaded file successfully!`));
        await getFiles()(dispatch);
    } catch (e) {
        dispatch({
            type: 'FILE_UPLOAD_ERROR',
            payload: getErrorMessage(e),
        });
        console.error('File upload error');
        dispatch(showErrorSnackbar(`File upload error`));
    }
};

export const downloadMyFile = (uuid: string) => async (dispatch: AppDispatch) => {
    try {
        const response = await getInstance().get<{ data: any; error: string }>(`/seeker/profile/files/${uuid}`);
        //presigned url back from api
        window.open(response.data.url, '_blank');
    } catch (e) {
        dispatch({
            type: 'FILE_DOWNLOAD_ERROR',
            payload: getErrorMessage(e),
        });
        console.error('Download file error');
        dispatch(showErrorSnackbar(`Download file error`));
    }
};

export const downloadProfileFile = (uuid: string, userId: string) => async (dispatch: AppDispatch) => {
    try {
        const response = await getInstance().get<{ data: any; error: string }>(`/employer/search/profile/file/${uuid}?id=${userId}`);
        window.open(response.data.url, '_blank');
    } catch (e) {
        dispatch({
            type: 'FILE_DOWNLOAD_ERROR',
            payload: getErrorMessage(e),
        });
        console.error('Download file error');
        dispatch(showErrorSnackbar(`Download file error`));
    }
};

export const deleteFile = (uuid: string) => async (dispatch: AppDispatch) => {
    try {
        await getInstance().delete<{ error: string }, any>(`/seeker/profile/files/${uuid}`);
        await getFiles()(dispatch);
    } catch (e) {
        dispatch({
            type: 'FILE_DELETE_ERROR',
            payload: getErrorMessage(e),
        });
        console.error('File delete error');
        dispatch(showErrorSnackbar(`File delete error`));
    }
};
