import { Grid } from '@mui/material';
// import Maps from '../../../components/maps/Map';
import Map from '../../../components/maps/MapComponent';

const TestPage = () => {
    return (
        <Grid xl={12}>
            <Map />
        </Grid>
    );
};

export default TestPage;
