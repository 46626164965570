import { useDispatch } from 'react-redux';
import { Grid, Divider, Typography, Card, CardContent, Button } from '@mui/material';
import { getAdminUserExport } from '../../../redux/actions/adminAction';
import { useUserMetrics } from '../../../hooks/useUserMetrics';

const AdminDashboard = () => {
    const dispatch = useDispatch();
    const { seeking, open, notSeeking, unknown, isNull } = useUserMetrics();

    const onExportClick = () => dispatch(getAdminUserExport());

    return (
        <>
            <Typography variant="h4" p={2}>
                Admin Dashboard
            </Typography>

            <Divider></Divider>
            <br />

            <Grid container padding={1}>
                <div style={{ display: 'flex', rowGap: '1rem', columnGap: '1rem', maxWidth: '100%', flexDirection: 'column' }}>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography variant="h5" component="div" gutterBottom>
                                Export Users
                            </Typography>
                            <Button variant="contained" onClick={onExportClick}>
                                Download
                            </Button>
                        </CardContent>
                    </Card>

                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography variant="h5" component="div" gutterBottom>
                                User Metrics
                            </Typography>
                            <Typography variant="body1">
                                {seeking.label} {seeking.value}
                            </Typography>
                            <Typography variant="body1">
                                {open.label} {open.value}
                            </Typography>
                            <Typography variant="body1">
                                {notSeeking.label} {notSeeking.value}
                            </Typography>
                            {isNull ? (
                                <Typography variant="body1">
                                    {unknown.label} {unknown.value}
                                </Typography>
                            ) : null}
                        </CardContent>
                    </Card>
                </div>
            </Grid>
        </>
    );
};

export default AdminDashboard;
