import React, { useContext, useEffect, useState } from 'react';
import { defaultConfig, DynamicConfig, dynamicConfigUrl } from './config';
import { setup } from '../services/fetch';

interface DynamicConfigContext {
    config: DynamicConfig;
    error: boolean;
    setConfig: (newConfig: DynamicConfig) => void;
}

export const configContextObject = React.createContext<DynamicConfigContext>({
    config: defaultConfig,
    error: false,
    setConfig: () => ({}),
});

export const useConfig = () => useContext(configContextObject);
export const configureFetch = async () => {
    const response = await fetch(`/${dynamicConfigUrl}`);
    const json = await response.json();
    setup(json.apiUrl);
    return json;
};

const ConfigContextProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
    const [configState, setConfigState] = useState(defaultConfig);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        const _getConfig = async () => {
            try {
                const json = await configureFetch();
                setConfigState(json);
                setError(false);
            } catch (error) {
                setError(true);
            }
        };
        _getConfig();
    }, []);

    return (
        <configContextObject.Provider
            value={{
                config: configState,
                error,
                setConfig: setConfigState,
            }}
        >
            {children}
        </configContextObject.Provider>
    );
};

export default ConfigContextProvider;
