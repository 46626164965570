import { sha256 } from 'js-sha256';
import { randomValuesOnly } from '@aws-crypto/random-source-browser';
import { bytesToBase64 } from './encoding';

const convertB64 = (str: string): string => str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
const VERIFIER_KEY = 'v_key';

export const getChallenge = async (): Promise<string> => {
    const _verifier = await getVerifier();
    const hash = sha256.create();
    return convertB64(bytesToBase64(hash.update(_verifier).digest()));
};

export const getVerifier = async (): Promise<string> => {
    let verifier = sessionStorage.getItem(VERIFIER_KEY);
    if (!verifier) {
        const randomData = await randomValuesOnly(32);
        verifier = convertB64(bytesToBase64(randomData));
        sessionStorage.setItem(VERIFIER_KEY, verifier);
    }
    return verifier;
};

export const clearVerifier = (): void => {
    sessionStorage.removeItem(VERIFIER_KEY);
};
