import { getInstance } from '../../services/fetch';
import { AppDispatch } from '../store';
import { showErrorSnackbar } from './snackbarAction';
import { upsertProfile } from './profileAction';

export const getUserInfo = () => async (dispatch: AppDispatch) => {
    try {
        const response = await getInstance().get<{ data: any; error: string }>(`/auth/priv/user`);
        dispatch({
            type: 'GET_USER',
            payload: response.data.token,
        });

        const email = response.data.cognito.Attributes.find((x: any) => x.Name === 'email').Value;
        dispatch(upsertProfile({ email }));
    } catch (e: any) {
        dispatch({
            type: 'GET_USER_ERROR',
            payload: e?.message,
        });
        dispatch(showErrorSnackbar(`An error occurred while getting the user data`));
    }
};

export const logout = (deleteAccount: boolean) => async (dispatch: AppDispatch) => {
    try {
        await getInstance().get<{ data: any; error: string }>(`/auth/logout?deleteAccount=${deleteAccount}`);
        // see "/services/fetch.ts" for logout redirect
    } catch (e: any) {
        dispatch({
            type: 'LOGOUT_ERROR',
            payload: e?.message,
        });
        dispatch(showErrorSnackbar(`An error occurred during logout`));
    }
};
