import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { AuthContext } from '../../auth/AuthProvider';
import { logout } from '../../redux/actions/userAction';
import { useConfig } from '../../config/ConfigProvider';
import { setup } from '../../services/fetch';

const Root = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    flex-direction: column;
    font-size: 30px;

    @keyframes flickerAnimation {
        /* flame pulses */
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.1;
        }
        100% {
            opacity: 1;
        }
    }
`;

const PleaseRetry = styled.a`
    color: black;
    text-align: center;

    p {
        margin: 0;
    }
`;

const Logout = (): ReactElement => {
    const dispatch = useDispatch();
    const [error] = useState<string | undefined>(undefined);
    const authContext = useContext(AuthContext);
    const { config } = useConfig();

    useEffect(() => {
        if (config?.apiUrl) {
            const _logout = async () => {
                setup(config.apiUrl);
                authContext.setToken(undefined);
                dispatch(logout(false));
            };
            _logout();
        }
    }, [authContext, config, dispatch]);

    if (error) {
        return (
            <Root>
                <PleaseRetry href={`${config.serverUri}/logout`}>
                    <p>Oops, something went wrong!</p>
                    <p>Please click here to try again</p>
                </PleaseRetry>
            </Root>
        );
    }
    return <Root>Logging out...</Root>;
};

export default Logout;
