import React from 'react';
import PublicCandidateSearch from './employer';
import JobBoard from './jobseeker';
import { FormControlLabel, Grid, Switch } from '@mui/material';

enum selectedType {
    jobSeeker = 'jobseeker',
    employer = 'employer',
}

const DemoPage: React.FC = () => {
    const [selected, setSelected] = React.useState<string>(selectedType.employer);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected(event.target.checked ? selectedType.employer : selectedType.jobSeeker);
    };
    return (
        <Grid container>
            <Grid xs={12} item display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <>
                    <FormControlLabel
                        value="publicView"
                        control={<Switch checked={selected === selectedType.employer} onChange={handleChange} color="default" />}
                        label="Find a Job"
                        labelPlacement="start"
                    />
                    <p className="ml-1">Find Talent</p>
                </>
            </Grid>
            <Grid xs={12} item>
                {selected === selectedType.employer && <PublicCandidateSearch />}
                {selected === selectedType.jobSeeker && <JobBoard />}
            </Grid>
        </Grid>
    );
};

export default DemoPage;
