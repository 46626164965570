import { getInstance } from '../../services/fetch';
import { AppDispatch } from '../store';
import { Ping } from '../../types/ping';
import { showErrorSnackbar, showSuccessSnackbar } from './snackbarAction';
import { getErrorMessage } from '../../utils/getErrorMessage';

export const sendPing = (ping: Ping) => async (dispatch: AppDispatch) => {
    try {
        await getInstance().post<{ error: string }, any>(`/employer/ping`, ping);
        dispatch(showSuccessSnackbar(`Contact request sent successfully!`));
    } catch (e) {
        dispatch({
            type: 'SEND_PING_ERROR',
            payload: getErrorMessage(e),
        });
        console.error('Send ping error');
        dispatch(showErrorSnackbar(`Contact request error`));
    }
};
